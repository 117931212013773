import { TranslateString as t } from '../utils/DictionaryUtils'

export default {
    fi: {
        'ui__navi--dashboard': 'Työpöytä',
        'ui__navi--customers': 'Asiakkaat',
        'ui__navi--assignments': 'Toimeksiannot',
        'ui__navi--marketing': 'Markkinointi',
        'ui__navi--intranet': 'Intranet',
        'ui__navi--reports': 'Raportit',
        'ui__navi--settings': 'Asetukset',
        'ui__navi--housing': 'Taloyhtiöt',
        'ui__navi--help': 'Ohjeet',
        'ui__navi--support': 'Etätuki',
        'ui__navi--extranet': 'Extranet',
        'ui__navi--back': 'Takaisin',
        'ui__navi--marketing-showing-times': 'Esittelyajat',
        'ui__navi--marketing-markets': 'Markkinapaikat',
        'ui__navi--marketing-magazines': 'Lehdet',
        'ui__navi--customers-search': 'Asiakashaku',
        'ui__navi--customers-leads': 'Asiakasliidit',
        'ui__navi--assignments-sale': 'Myyntitoimeksianto',
        'ui__navi--assignments-sales': 'Myyntitoimeksiannot',
        'ui__navi--assignments-rent': 'Vuokratoimeksiannot',
        'ui__navi--assignments-acquisition': 'Ostotoimeksiannot',
        'ui__navi--assignments-new-acquisition': 'Uudet ostotoimeksiannot',
        'ui__navi--assignments-acquisition-single': 'Ostotoimeksianto',
        'ui__navi--reports-power-bi': 'Power BI',
        'ui__navi--reports-rent-list': 'Vuokralista',
        'ui__navi--reports-assignments-acquisition': 'Ostotoimeksiannot',
        'ui__navi--reports-terminated-targets': 'Irtisanotut kohteet',
        'ui__navi--reports-sales-list': 'Myyntilista',
        'ui__navi--reports-journal': 'Päiväkirja',
        'ui__navi--reports-brokerage': 'Välityspalkkio',
        'ui__navi--reports-sold-targets': 'Myydyt kohteet',
        'ui__navi--reports-events-by-customer': 'Tapahtumat asiakkaittain',
        'ui__navi--reports-acquisition-channels': 'Hankintakanavat',
        'ui__navi--reports-bills': 'Laskut',
        'ui__navi--reports-sold-targets-by-type': 'Myydyt kohteet tyypeittäin',
        'ui__navi--settings-news': 'Uutiset / tiedotteet',
        'ui__navi--settings-offices-admin': 'Toimipisteet (ylläpito)',
        'ui__navi--settings-offices': 'Toimipisteet',
        'ui__navi--settings-staff-admin': 'Henkilöstö (ylläpito)',
        'ui__navi--settings-staff': 'Henkilöstö',
        'ui__navi--settings-user-groups': 'Käyttäjäryhmät',
        'ui__navi--settings-companies': 'Taloyhtiöt',
        'ui__navi--settings-rescission': 'Reskontra',
        'ui__navi--settings-bills': 'Laskut',
        'ui__navi--settings-magazines': 'Lehdet',
        'ui__navi--settings-about': 'Tietoja ohjelmasta',
        'ui__navi--settings-environment': 'Käyttöympäristö',
        'ui__navi--settings-help': 'Ohjeet',
        'ui__navi--settings-personal-info': 'Omat tiedot',
        'ui__navi--settings-support': 'Etätuki',
        'ui__navi--settings-website-update': 'Sivuston päivitys',
        'ui__navi--settings-jobs': 'Työpaikat',
        'ui__navi--settings-intranet': 'Intranetin hallinta',
        'ui__navi--settings-feedback': 'PDX+ käyttäjäpalaute',
        'ui__navi--settings-export': 'Vie Oikotie/Etuovi',
        'ui__navi--intranet-internal-news': 'Sisäiset tiedotteet',
        'ui__navi--intranet-calendar': 'Tapahtumakalenteri',
        'ui__navi--intranet-offices': 'Toimipisteemme',
        'ui__navi--intranet-person-search': 'Henkilöhaku',
        'ui__navi--intranet-legal': 'Lakitietoa',
        'ui__navi--intranet-document-archive': 'Asiakirjapankki',
        'ui__months-1': 'Tammikuu',
        'ui__months-2': 'Helmikuu',
        'ui__months-3': 'Maaliskuu',
        'ui__months-4': 'Huhtikuu',
        'ui__months-5': 'Toukokuu',
        'ui__months-6': 'Kesäkuu',
        'ui__months-7': 'Heinäkuu',
        'ui__months-8': 'Elokuu',
        'ui__months-9': 'Syyskuu',
        'ui__months-10': 'Lokakuu',
        'ui__months-11': 'Marraskuu',
        'ui__months-12': 'Joulukuu',
        'ui__general--close': 'Sulje',
        'ui__general--loading': 'Ladataan',
        'ui__general--edit': 'Muokkaa',
        'ui__general--edit-stop': 'Lopeta muokkaus',
        'ui__general--add': 'Lisää',
        'ui__general--assign': 'Aseta',
        'ui__general--edit-content': 'Muokkaa sisältöä',
        'ui__general--select-user': 'Valitse käyttäjä',
        'ui__general--select': 'Valitse',
        'ui__general--type': 'Tyyppi',
        'ui__general--status': 'Tila',
        'ui__general--logout': 'Kirjaudu ulos',
        'ui__general--save': 'Tallenna',
        'ui__general--send': 'Lähetä',
        'ui__general--print': 'Tulosta',
        'ui__general--save-and-print': 'Tallenna ja tulosta',
        'ui__general--processing': 'Käsitellään',
        'ui__general--page': 'Sivu',
        'ui__general--search-units': 'Kappaletta',
        'ui__general--documents': 'Dokumentit',
        'ui__general--actions': 'Toiminnot',
        'ui__general--alv': 'ALV',
        'ui__general--total': 'Yhteensä',
        'ui__general--reward': 'Palkkio',
        'ui__general--file': 'Tiedosto',
        'ui__general--files': 'Tiedostoa',
        'ui__general--browse': 'Selaa',
        'ui__general--filename': 'Tiedostonimi',
        'ui__general--file-type': 'Tiedoston tyyppi',
        'ui__general--file-to-be-added': 'Lisättävä tiedosto',
        'ui__general--description': 'Kuvaus',
        'ui__general--created': 'Lisäyspvm.',
        'ui__general--created-file': 'Luotu',
        'ui__general--download': 'Lataa',
        'ui__general--signing-start': 'Käynnistä allekirjoitusvaihe',
        'ui__general--sign': 'Allekirjoita dokumentti',
        'ui__general--sign-status': 'Allekirjoituksen tila',
        'ui__general--sign-done': 'Allekirjoitus tehty',
        'ui__general--sign-canceled': 'Allekirjoitus peruttu',
        'ui__general--delete': 'Poista',
        'ui__general--cancel': 'Peruuta',
        'ui__general--canceled': 'Peruttu',
        'ui__general--in-signing': 'Allekirjoituksessa',
        'ui__general--signed': 'Allekirjoitettu',
        'ui__general--new': 'Uusi',
        'ui__general--proceed': 'Jatka',
        'ui__general--last-valid-date': 'Viimeinen voimassaolopäivä',
        'ui__general--active': 'Aktiivinen',
        'ui__general--rejected': 'Hylätty',
        'ui__general--copy-info': 'Kopioi tiedot talteen',
        'ui__general--url': 'Osoite',
        'ui__general--password': 'Salasana',
        'ui__general--selected': 'Valittuna',
        'ui__general--not-selected': 'Ei valittu',
        'ui__general--normal': 'Normaali',
        'ui__general--custom': 'Mukautettu',
        'ui__general--email': 'Sähköpostiosoite',
        'ui__general--email-or-username': 'Sähköpostiosoite tai käyttäjätunnus',
        'ui__general--list-customer-name': 'Asiakkaan nimi',
        'ui__action--refresh-view': 'Päivitä näkymä',
        'ui__action--add-pep': 'Lisää PEP-kysely',
        'ui__action--add-file': 'Lisää tiedosto',
        'ui__action--view-file': 'Näytä tiedosto',
        'ui__action--download-file': 'Lataa tiedosto',
        'ui__action--open-details': 'Avaa tarkemmat tiedot',
        'ui__action--download-files': 'Lataa tiedostot',
        'ui__action--send-files-by-email': 'Lähetä tiedostot sähköpostitse',
        'ui__action--delete-files': 'Poista tiedostot',
        'ui__action--try-again': 'Yritä uudelleen',
        'ui__action--add-procurement': 'Lisää kohdehaku',
        'ui__validation--form-errors': 'Täytä ensin tähdellä (*) merkityt pakolliset kentät',
        'ui__validation--no-customers': 'Lisää ainakin yksi toimeksiantaja',
        'ui__validation--required-field': 'Pakollinen kenttä',
        'ui__validation--input-short': 'Arvo on liian lyhyt',
        'ui__validation--input-alphanumeric':
            'Käyttäjätunnus ei voi sisältää välilyöntejä, ääkkösiä tai erikoismerkkejä',
        'ui__validation--input-short-n': 'Arvo on liian lyhyt - syötä vähintään $0 merkkiä',
        'ui__validation--input-long-n': 'Arvo on liian pitkä - kork. $0 merkkiä',
        'ui__validation--input-email': 'Arvo ei ole sähköpostiosoite',
        'ui__validation--number-pos': 'Arvo ei saa olla negatiivinen',
        'ui__validation--number-nan': 'Arvon tulee olla numero',
        'ui__validation--number-more-than': 'Arvon tulee olla yli $0',
        'ui__validation--unsupported-file-type': 'Tiedostotyyppi ei ole tuettu.',
        'ui__validation--signer-n-name': 'Allekirjoittajan #$0 nimi.',
        'ui__validation--signer-n-idcode': 'Allekirjoittajan #$0 henkilötunnus.',
        'ui__validation--signer-n-email': 'Allekirjoittajan #$0 email.',
        'ui__validation--person-idcode-invalid': 'Hetu on virheellinen',
        'ui__error--add-file': 'Tiedoston lisääminen epäonnistui.',
        'ui__validation--tax-deal-date': 'Kaupantekopäivämäärä puuttuu. Tieto on pakollinen lähetettäessä',
        'ui__validation--tax-seller-info': 'Luovuttajien tiedot puuttuvat.',
        'ui__validation--tax-seller-info-error-ctt': 'Varainsiirtoveron luovuttajien tiedot virheelliset.',
        'ui__validation--tax-seller-info-error': 'Luovuttajien tiedot virheelliset.',
        'ui__validation--tax-buyer-info': 'Luovutuksensaajien tiedot puuttuvat.',
        'ui__validation--tax-buyer-info-error': 'Luovutuksensaajan tiedot virheelliset.',
        'ui__validation--tax-buyer-info-error-ctt': 'Varainsiirtoveron luovutuksensaajan tiedot virheelliset.',
        'ui__validation--tax-invalid-idcode': 'Henkilön $0 henkilötunnus on virheellinen.',
        'ui__validation--tax-office-address': 'Toimipisteen $0 osoite puuttuu.',
        'ui__validation--tax-office-zipcode': 'Toimipisteen $0 postinumero puuttuu.',
        'ui__validation--tax-office-city': 'Toimipisteen $0 postitoimipaikka puuttuu.',
        'ui__validation--tax-office-telephone': 'Toimipisteen $0 puhelinnumero puuttuu.',
        'ui__validation--tax-office-company-name': 'Toimipisteen $0 yrityksen nimi puuttuu.',
        'ui__validation--tax-office-company-vatcode': 'Toimipisteen $0 y-tunnus puuttuu.',
        'ui__validation--deal-split-exactly': 'Palkkiojaon yhteismäärä pitää olla 100 %',
        'ui__validation--deal-split-seller_error': 'Lisää myyjä. ',
        'ui__validation--no-procurement-results': 'Toimeksiannolla tulee olla ainakin yksi voimassaoleva kohdehaku.',
        'ui__validation--tax-target-new':
            'Huom! Kohde on uudiskohde. Muistathan, että uudiskohteissa ostaja tekee varainsiirtoveroilmoituksen itse.',
        'ui__error--delete-file': 'Tiedoston poistaminen epäonnistui.',
        'ui__error--delete-files': 'Tiedostojen poistaminen epäonnistui:',
        'ui__error--action-failed': 'Toiminto epäonnistui.',
        'ui__error--saving-failed': 'Tallennus epäonnistui.',
        'ui__error--connection-error': 'Yhteysvirhe',
        'ui__error--try-again': 'Yritä hetken päästä uudelleen.',
        'ui__error--connection-timeout': 'Yhteys taustajärjestelmään epäonnistui. Yritä hetken päästä uudelleen.',
        'ui__error--connection-missing': 'Yhteys taustajärjestelmään epäonnistui. Tarkista verkkoyhteys, kiitos.',
        'ui__error--unknown-saving': 'Virhe tiedoston tallennuksessa. Yritä hetken päästä uudelleen.',
        'ui__error--proplem-persists': 'Jos ongelma jatkuu, ole yhteydessä tukeemme:',
        'ui__error--fetch-failed': 'Tietojen haku epäonnistui.',
        'ui__error--email-failed': 'Sähköpostin lähetys epäonnistui.',
        'ui__info--email-success': 'Sähköpostin lähetys onnistui.',
        'ui__info--password-update-success': 'Salasana tallennettu',
        'ui__units--volume': 'kpl',
        'ui__filters--filter': 'Suodata',
        'ui__filters--reset': 'Nollaa suodatin',
        'ui__filters--reset-all': 'Nollaa suodattimet',
        'ui__order--sort': 'Järjestys',
        'ui__label--view-type': 'Näkymä',
        'ui__label--view-type-list': 'Lista',
        'ui__label--view-type-images': 'Kuvat',
        'ui__error--no-results': 'Ei tuloksia',
        'ui__search--placeholder': 'Etsi palvelusta...',
        'ui__search-by-name--placeholder': 'Etsi nimellä...',
        'ui__search-by-name-and-vat--placeholder': 'Etsi nimellä tai y-tunnuksella...',
        'ui__user-menu--profile': 'Käyttäjäprofiili',
        'ui__confirmation--data-saved': 'Tiedot tallennettu',
        'ui__confirmation--picture-saved': 'Kuva tallennettu',
        'confirm__form--heading': 'Toimeksiannon tilan muutos',
        'confirm__form--touched': 'Haluatko siirtyä pois? Tallentamattomat muutokset häviävät.',
        'confirm__modal--touched': 'Jatkaessasi tallentamattomat tiedot tallennetaan.',
        'confirm__modal--admin':
            'ADMINISTRATOR-käyttäjän ohitus - HUOM. Vaihda vain tilaa mikäli olet varma, että pakollisia kenttiä ei tarvita, tai jos olet vaihtamassa toimeksiannon tilaa alaspäin.',
        'confirm__form--assignment-missing-price':
            'Kaupanteossa maksetaan -arvoa ei ole asetettu. Haluatko silti jatkaa?',
        'confirm__assignment--duplicate': 'Haluatko kopioida toimeksiannon uudeksi?',
        'confirm__file--delete': 'Haluatko poistaa tiedoston',
        'confirm__file--delete-multiple': 'Haluatko poistaa seuraavat tiedostot',
        'confirm__archive--signing-cancel': 'Haluatko peruuttaa tiedoston allekirjoituksen?',
        'contact__general--removed': 'Poistettu',
        'contact__general--name': 'Nimi',
        'contact__general--forename': 'Etunimi',
        'contact__general--surname': 'Sukunimi',
        'contact__general--phone': 'Puhelinnumero',
        'contact__general--title': 'Titteli',
        'contact__general--email': 'Email',
        'contact__general--address': 'Osoite',
        'contact__general--zipcode': 'Postinumero',
        'contact__general--city': 'Kaupunki',
        'contact__general--post-office': 'Postitoimipaikka',
        'contact__general--hometown': 'Kotipaikka',
        'contact__general--idcode': 'Henkilötunnus',
        'contact__general--idcode-short': 'HeTu',
        'contact__general--bank-account': 'Tilinumero',
        'contact__general--bank-account-bic': 'BIC',
        'contact__general--description': 'Lisätietoja',
        'contact__general--person-in-charge': 'Vastuuhenkilö',
        'contact__general--contact-person': 'Yhteyshenkilö',
        'contact__general--assigner': 'Toimeksiantaja',
        'contact__general--assigner-placeholder': 'Hae asiakas tai lisää uusi...',
        'contact__general--assigners': 'Toimeksiantajat',
        'contact__general--approver': 'Suostumuksen antaja',
        'contact__general--warranter': 'Valtakirjan antaja',
        'contact__general--marketing-permission': 'Markkinointilupa',
        'contact__general--approve-data-saving': 'Suostumus henkilötietojen tallentamiseen',
        'contact__modify--add-new': 'Lisää uusi kontakti',
        'contact__modify--add-customer': 'Lisää toimeksiantaja',
        'contact__modify--add-buyer': 'Lisää ostaja',
        'contact__modify--add-seller': 'Lisää myyjä',
        'contact__modify--edit': 'Muokkaa kontaktia',
        'contact__type--company': 'Yritys',
        'contact__type--person': 'Henkilö',
        'contact__type--man': 'Mies',
        'contact__type--woman': 'Nainen',
        'contact__type--group': 'Ryhmä',
        'contact__type--heirs': 'Perikunta',
        'contact__header--customer-card': 'Asiakaskortti',
        'contact__header--customer-new': 'Uusi asiakas',
        'company__general--vat-code': 'Y-tunnus',
        'company__general--hometown': 'Kotipaikka',
        'company__general--housing-registry': 'Sähköisessä asunto-osakerekisterissä',
        'company__general--manager-company': 'Isännöitsijätoimisto',
        'company__general--manager-name': 'Isännöitsijän nimi',
        'company__general--manager-address': 'Isännöitsijän osoite',
        'company__general--manager-email': 'Isännöitsijän sähköposti',
        'company__general--manager-phone': 'Isännöitsijän puhelin',
        'company__general--manager-cert-date': 'Isännöitsijäntodistuksen pvm',
        'company__general--propertymanagement': 'Kiinteistönhuolto',
        'company__general--feebase': 'Vastikeperuste',
        'company__general--possessions': 'Yhtiö omistaa',
        'company__general--returns-per-year': 'Tuotot/vuosi',
        'company__general--returns-year': 'Tuottojen vuosi',
        'company__general--loans': 'Pitkäaikaiset lainat',
        'company__general--loans-date': 'Lainojen pvm',
        'company__general--redemption': 'Lunastuspykälä (asunnot/muut)',
        'company__general--redemption-carslot': 'Lunastuspykälä (autopaikat)',
        'company__general--restrictions': 'Käyttö- ja luovutusrajoitukset',
        'company__general--obligations': 'Muut velvoitteet',
        'company__general--extra': 'Lisätiedot yhtiöstä',
        'company__general--site-ownership': 'Tontin omistus',
        'company__general--site-ownership-own': 'Oma',
        'company__general--site-ownership-rent': 'Vuokra',
        'company__general--site-ownership-optional-rent': 'Valinnainen vuokra',
        'company__general--site-redeemed': 'Lunastettu',
        'company__general--site-redeemed-yes': 'Kyllä',
        'company__general--site-redeemed-no': 'Ei',
        'company__general--site-area': 'Tontin pinta-ala',
        'company__general--rental-site-info': 'Tontin lisätiedot',
        'company__general--landlord': 'Vuokranantaja',
        'company__general--rent-last-date': 'Vuokra-aika (pvm asti)',
        'company__general--rent-per-year': 'Vuokra/vuosi',
        'company__general--built-year': 'Rakennusvuosi',
        'company__general--buildingmaterial': 'Rakennusmateriaali',
        'company__general--roof': 'Kattotyyppi/kate',
        'company__general--floors-total': 'Kerroksia talossa',
        'company__general--warming': 'Lämmitys',
        'company__general--builder': 'Rakentaja',
        'company__general--flats': 'Asuinhuoneistoja',
        'company__general--flat-area': 'Asuinhuoneistojen pinta-ala',
        'company__general--offices': 'Liikehuoneistoja',
        'company__general--officearea': 'Liikehuoneistojen pinta-ala',
        'company__general--volume': 'Tilavuus',
        'company__general--antenna': 'Antenni',
        'company__general--carslots': 'Autopaikat',
        'company__general--sauna': 'Taloyhtiössä sauna',
        'company__general--common-area': 'Muut yhteiset tilat',
        'company__general--ventilation': 'Ilmanvaihto',
        'company__general--broadband': 'Laajakaista',
        'company__general--basic-repairs': 'Tehdyt korjaukset',
        'company__general--coming-repairs': 'Tulevat korjaukset',
        'company__general--new-housing': 'Uudiskohde',
        'company__general--construction-phase': 'Rakennusvaihe',
        'company__general--city': 'Yhtiön kunta',
        'company__general--site-number': 'Tontin nro/Tilan rakennusnro',
        'company__general--construction-bank-office': 'Rakennusvaiheen pankki',
        'company__general--construction-bank-account': 'Rakennusvaiheen tilinumero',
        'company__general--construction-bank-iban': 'Rakennusvaiheen IBAN',
        'company__general--construction-bank-bic': 'Rakennusvaiheen BIC',
        'company__general--bank': 'Pankki',
        'company__general--bank-office': 'Kauppahinta pankki',
        'company__general--bank-account': 'Kauppahinta tilinumero',
        'company__general--bank-iban': 'Kauppahinta IBAN',
        'company__general--bank-bic': 'Kauppahinta BIC',
        'company__general--barred-bank-office': 'Sulkutili pankki',
        'company__general--barred-bank-account': 'Sulkutili tilinumero',
        'company__general--barred-bank-iban': 'Sulkutili IBAN',
        'company__general--barred-bank-bic': 'Sulkutili BIC',
        'company__general--interest-rate': 'Hyvityskorko',
        'company__general--breach-of-contract': 'Maksuviivästyksen vuoksi purettu kauppa',
        'company__general--breach-of-contract-compensation':
            'Myyjällä oikeus asuntokauppalain mukaiseen vahingonkorvaukseen',
        'company__general--breach-of-contract-fees-and-interest':
            'Ostajan maksettava kulut sekä 2% velattomasta hinnasta',
        'company__general--satety-doc-keeper': 'Turva-asiakirjojen ja osakekirjojen säilyttäjä',
        'company__general--satety-doc-keeper-address': 'Säilytyspaikan osoite',
        'company__general--satety-doc-keeper-signature': 'Turva-asiakirjojen säilyttäjä (allekirjoitus)',
        'company__general--construction-phase-deposit': 'Rakentamisvaiheen vakuus',
        'company__general--incapability-deposit': 'Suorituskyvyttömyysvakuus',
        'company__general--deposit-place-date': 'Vakuuden paikka ja aika',
        'company__general--deposit-attachment': 'Liitteenä todistus, että vakuudet taloyhtiön hallussa',
        'company__general--built-date-earliest': 'Valmis aikaisintaan',
        'company__general--built-date-latest': 'Valmis viimeistään',
        'company__general--built-date-estimated': 'Arvioitu valmistumisaika',
        'company__general--building-permit-applied': 'Rakennuslupaa haettu',
        'company__general--building-permit-info': 'Rakennuslupaan liittyvät lisätiedot',
        'company__general--contract-attachemnts': 'Sopimuksen liitteiden nimet',
        'company__general--contract-attachemnts-carslot': 'Sopimuksen liitteiden nimet (autopaikat)',
        'company__general--contract-attachemnts-office': 'Sopimuksen liitteiden nimet (liiketilat)',
        'company__general--additional-terms': 'Muut ehdot',
        'company__general--additional-terms-carslot': 'Muut ehdot (autopaikat)',
        'company__general--additional-terms-office': 'Muut ehdot (liiketilat)',
        'dias__general--prerequisites-buyer-bank': 'Ostajan pankki',
        'dias__general--prerequisites-seller-bank': 'Myyjän pankki',
        'dias__general--prerequisites-bank-has-accepted': 'Hyväksytty',
        'dias__general--prerequisites-bank-is-waiting-for-approval': 'Odottaa hyväksyntää',
        'dias__general--prerequisites-bank-approval-waiting-timeout': 'Hyväksyntä viivästynyt',
        'dias__general--dias-process-aborted': 'Digitaalista asuntokauppaa ei voida aloittaa',
        'dias__general--dias-process-restart': 'Aloita DIAS alusta',
        'dias__general--dias-process-cancel': 'Keskeytä DIAS-kauppa',
        'dias__general--interrupt_reason': 'Keskeytyksen syy',
        'dias__general--dias-process-ongoing': 'DIAS-kauppa on käynnissä',
        'dias__general--signing-step-heading-signing': 'Allekirjoitukset',
        'dias__general--signing-step-signing-pending': 'Odottaa allekirjoituksia',
        'dias__general--signing-step-signing-done': 'Kaikki allekirjoittaneet',
        'dias__general--signing-step-signing-error': 'Allekirjoituksien aikaraja umpeutui',
        'dias__general--signing-step-waiting-for-payment': 'Odottaa maksusuorituksia',
        'dias__general--signing-step-seller-bank-payment': 'Ostajan pankin maksut',
        'dias__general--signing-step-buyer-bank-payment': 'Myyjän pankin maksut',
        'dias__general--signing-step-seller-bank-sending-share': 'Myyjän pankki lähettää osakekirjat ostajan pankille',
        'dias__general--signing-step-wait-sending-share': 'Odottaa lähetystä',
        'dias__general--signing-step-share-sent': 'Osakekirjat lähetetty',
        'dias__general--signing-step-buyer-bank-received-share': 'Ostajan pankki on vastaanottanut osakekirjat',
        'dias__general--signing-step-wait-receiving-share': 'Odottaa osakekirjoja',
        'dias__general--signing-step-received-share': 'Osakekirjat vastaanotettu',
        'dias__general--signing-step-payment-done': 'Maksut suoritettu',
        'dias__general--signing-step-payment-error': 'Maksusuorituksien aikaraja umpeutui',
        'dias__general--signing-step-heading-signing-completed': 'Kauppa valmis',
        'dias__general--share-of-sale-fee': 'Osuus välityspalkkiosta',
        'dias__general--share-of-sales-tax': 'Osuus varainsiirtoverosta',
        'dias__general--includes-document-fees': 'Sisältää asiakirjakulut',
        'dias__general--processing-info-text':
            'Kauppa on aloitettu ja kauppatietojen prosessoinnissa voi kestää hetken.',
        'dias__errors--check-seller-fieds': 'Tarkista myyjien tiedot.',
        'dias__errors--check-dealer-fieds': 'Tarkista Välittäjän tiedot.',
        'dias__errors--moved-to-manual-process': 'DIAS-kauppa on siirtynyt manuaaliseen käsittelyyn.',
        'dias__errors--too-many-deed-attachments': 'Valitse vain yksi kauppakirja liitetiedostoista.',
        'dias__errors--no-added-deed-attachment': 'Kauppakirjaa ei ole valittu liitteeksi.',
        'dias__errors--no-added-sharebook-attachment': 'Osakekirjaa ei ole valittu liitteeksi.',
        'dias__errors--not-a-pdf-attachment': 'Valitse ainoastaan pdf-tiedostoja liitteeksi.',
        'dias__errors--timeout':
            'DIAS-kaupan tilan tarkastaminen ei juuri nyt onnistunut. Yritä hetken kuluttua uudelleen.',
        'dias__errors--check-seller-field-fees': 'Tarkista myyjien välityspalkkioiden määrä. Summa ei täsmää.',
        'dias__errors--seller-fee-sum-invalid': 'Summa ei täsmää.',
        'dias__errors--attachment-size-too-big':
            'Tiedoston koko saa olla enintään 8 Mt. Seuraavat liitteet ovat liian suuret:',
        'dias__errors--aborted-for-a-reason': 'DIAS-kauppaa ei voitu aloittaa. Katso virheviesti alta.',
        'dias__headings--seller-information': 'Myyjien tiedot',
        'dias__headings--buyer-information': 'Ostajien tiedot',
        'dias__headings--start-sale': 'Käynnistä kauppa',
        'dias__headings--missing-info-seller': 'Myyjän tiedot puuttuvat.',
        'dias__headings--missing-info-buyer': 'Ostajan tiedot puuttuvat.',
        'dias__headings--missing-info-sales-tax': 'Varainsiirtovero-tiedot puuttuvat.',
        'dias__headings--invalid-info-sales-tax': 'Varainsiirtoveron tiedot eivät vastaa ostajien tietoja.',
        'dias__headings--missing-info-deed': 'Kauppakirja PDF puuttuu arkistosta.',
        'dias__headings--missing-info-seller-missing-fieds':
            'Myyjän #$1 tiedot ovat puutteelliset. Lisää seuraavat kentät: ',
        'dias__headings--missing-info-buyer-missing-fieds':
            'Ostajan #$1 tiedot ovat puutteelliset. Lisää seuraavat kentät: ',
        'dias__headings--deed-missing-from-archive': 'Kauppakirja puuttuu arkistosta.',
        'dias__headings--missing-fields':
            ' Puuttuvat kentät (Sopimustiedot-, Kohteen tiedot- tai Kauppatiedot-sivulla): ',
        'dias__headings--missing-assignment-information': 'Kohteen tiedot ovat puutteelliset.',
        'dias__headings--missing-deal-fee': 'Kohteen tiedoista puuttuu välityspalkkio.',
        'dias__headings--missing-iban-or-bic': 'Toimipisteen asetuksista puuttuu tilinumero/BIC.',
        'dias__headings--bic-error': 'Virheellinen BIC.',
        'dias__headings--add-missing-deal-fee': 'Lisää välityspalkkio',
        'dias__headings--add-info-seller': 'Lisää myyjän tiedot',
        'dias__headings--add-info-buyer': 'Lisää ostajan tiedot',
        'dias__headings--add-info-bank': 'Pankki',
        'dias__headings--seller-banks-must-be-same': 'DIAS-kaupassa myyjillä tulee olla sama pankkikonttori',
        'dias__headings--buyer-banks-must-be-same': 'DIAS-kaupassa ostajilla tulee olla sama pankkikonttori',
        'dias__headings--add--infosales-tax': 'Lisää varainsiirtovero-tiedot',
        'dias__headings--add--fix-correct-customers': 'Korjaa ostajien tiedot',
        'dias__headings--add--fix-address': 'Korjaa kohteen osoite: $0',
        'dias__headings--add-info-deed': 'Lisää kauppakirja täältä',
        'dias__headings--add-info-assignment': 'Lisää tarvittavat tiedot',
        'dias__headings--add-deed-to-archive': 'Lisää kauppakirja arkistoon',
        'dias__headings--add-customer-bank-information': 'Lisää tilitiedot',
        'dias__headings--add-iban-and-bic': 'Lisää tilinumero asetuksiin',
        'dias__headings--sale-process-canceled': 'Digitaalinen asuntokauppa on peruttu.',
        'dias__headings--sale-reference-number': 'Viitenumero',
        'dias__headings--dealer-details': 'Välittäjän tiedot',
        'dias__headings--dealer-details-email-missing': 'Välittäjän toimipisteen sähköposti puuttuu',
        'dias__headings--sale-attachments': 'Kauppaan liittyvät liitteet',
        'dias__headings--attachment-observe':
            'Huom! Tiedostotyypit isännöitsijäntodistus ja osakekirja välitetään vain pankkiin, kaikki muut tiedostotyypit näkyvät kaupan kaikille osapuolille.',
        'dias__headings--information-correct-agreement':
            'Olen tarkistanut että yllä olevat tiedot sekä liitteiden tiedot ovat oikein ennen kuin aloitan digitaalisen asuntokaupan.',
        'dias__headings--refresh-status-polling': 'Päivitä tilanseuranta',
        'dias__headings--third-party-text1': 'Valtuutus',
        'dias__headings--third-party-text2':
            'Oikotie ja PDX hyödyntävät palvelussaan kolmannen osapuolen tuottamia palveluita (mm. VEROTTAJA, DIAS). Emme vastaa kolmannen osapuolen palvelusta. Vastaamme vain siltä osin, kun Oikotieltä ja PDX:stä on siirretty tietoja kolmannen osapuolen järjestelmiin. Kun tietoja palautuu kolmansilta osapuolilta esim. arkistointia varten, vastaamme tietojen säilyttämisestä siltä osin. Välittäjä vastaa syöttämistään tiedoista ja niiden oikeellisuudesta.',
        'dias__headings--third-party-text3': 'Antamalla valtuutuksen voit jatkaa palvelun käyttöä.',
        'dias__headings--event-log': 'Tapahtumahistoria',
        'dias__headings--event-log-timestamp': 'Aikaleima',
        'dias__headings--event-log-event': 'Tapahtuma',
        'dias__headings--event-log-no-events': 'Ei tapahtumia',
        'dias__headings--event-processing-title': 'Kauppaa aloitetaan',
        'assignment__headings--contract-info': 'Sopimustiedot',
        'assignment__headings--procurements': 'Kohdehaku',
        'assignment__headings--target-info': 'Kohteen tiedot',
        'assignment__headings--housing': 'Yhtiötiedot',
        'assignment__headings--property': 'Kiinteistötiedot',
        'assignment__headings--building': 'Rakennustiedot',
        'assignment__headings--marketing': 'Kohteen markkinointi',
        'assignment__headings--transaction': 'Kauppatiedot',
        'assignment__headings--dias': 'Digitaalinen asuntokauppa',
        'assignment__headings--dias-prerequisites': 'Digitaalisen asuntokaupan edellytysten tarkistus',
        'assignment__headings--dias-signing': 'Digitaalisen asuntokaupan seuranta',
        'assignment__headings--dias-signing-info':
            'Muista tehdä varainsiirtoilmoitukset, kun kauppahinnan maksusuoritukset ovat suoritettu.',
        'assignment__headings--dias-attachments': 'Kauppaan liittyvät liitteet',
        'assignment__headings--contractforms': 'Lomakkeet',
        'assignment__headings--tradebids': 'Tarjouskauppa',
        'assignment__headings--languagestab': 'Kenttien lokalisointi',
        'assignment__headings--archive': 'Arkisto',
        'assignment__headings--buy-offers': 'Ostotarjoukset',
        'assignment__headings--pep-forms': 'PEP-kyselyt',
        'assignment__headings--diary': 'Päiväkirja',
        'assignment__headings--flat-info': 'Huoneiston tiedot',
        'assignment__headings--pricing-fees': 'Hinta- ja asuinkustannukset',
        'assignment__headings--other-info': 'Muut tiedot',
        'assignment__headings--energy-classification': 'Energialuokitus',
        'assignment__headings--internet': 'Internet',
        'assignment__headings--multimedia-links': 'Medialinkit',
        'assignment__headings--photos': 'Kohteen kuvat',
        'assignment__headings--contract-basics': 'Sopimuksen perustiedot',
        'assignment__headings--responsible-persons': 'Vastuuhenkilöt',
        'assignment__headings--bids': 'Ostotarjoukset',
        'assignment__headings--sales-info': 'Kauppatiedot',
        'assignment__headings--sales-tax': 'Varainsiirtoveroilmoitus',
        'assignment__headings--sales-tax-sent': 'Varainsiirtoveroilmoitus lähetetty',
        'assignment__headings--fee-division': 'Palkkion jako edustajille',
        'assignment__headings--select-tax-receiver': 'Valitse luovutuksensaaja',
        'assignment__headings--space-n-storage': 'Tilat ja säilytys',
        'assignment__headings--levels-n-materials': 'Tasot ja materiaalit',
        'assignment__headings--dishwashers': 'Astianpesukoneet',
        'assignment__headings--stoves-n-ovens': 'Liedet, uunit ja keittotasot',
        'assignment__headings--refridgeration': 'Kylmälaitteet',
        'assignment__headings--sauna': 'Sauna',
        'assignment__headings--showers': 'Suihkut',
        'assignment__headings--baths': 'Ammeet',
        'assignment__headings--machinery': 'Kodinkoneet ja LVI-tekniikka',
        'assignment__headings--others': 'Muut',
        'assignment__headings--set-position': 'Aseta sijainti',
        'assignment__headings--floor-material': 'Lattiamateriaali',
        'assignment__label--internet-target-number': 'Kohdenumero',
        'assignment__label--assignment-number': 'Toimeksiantonumero',
        'assignment__headings--extend-contract-terms': 'Jatkosopimuksen ehdot',
        'assignment__label--number': 'Työnro',
        'assignment__label--name': 'Nimi',
        'assignment__label--address': 'Katuosoite',
        'assignment__label--district': 'Kaupunginosa',
        'assignment__label--country': 'Maa',
        'assignment__label--region': 'Maakunta',
        'assignment__label--coordinates': 'Sijainti kartalla',
        'assignment__label--room-count': 'Huoneita',
        'assignment__label--area-living': 'Asuinpinta-ala',
        'assignment__label--area-additional': 'Muu ala',
        'assignment__label--area-total': 'Kokonaisala',
        'assignment__label--area-extra-info': 'Lisätietoja pinta-alasta',
        'assignment__label--balcony-description': 'Lisätietoja parvekkeesta',
        'assignment__label--terrace-description': 'Lisätietoja terassista',
        'assignment__label--flat-window': 'Ikkunoiden suunta',
        'assignment__label--target-features': 'Kohteen ominaisuudet',
        'assignment__label--other-details': 'Muita tietoja',
        'assignment__label--area-information': 'Lisätietoja pinta-alasta',
        'assignment__label--balcony': 'Parveke',
        'assignment__label--terrace': 'Terassi',
        'assignment__label--sauna': 'Sauna',
        'assignment__label--lift': 'Hissi',
        'assignment__label--asbestos-survey': 'Asbestikartoitus',
        'assignment__label--asbestos-description': 'Lisätietoja asbestikartoituksesta',
        'assignment__label--beach-type': 'Ranta',
        'assignment__label--waterfront-type': 'Rantatyyppi',
        'assignment__label--driving-guide': 'Ajo-ohje',
        'assignment__label--share-nos': 'Osakenumerot',
        'assignment__label--share-nos-other': 'Muut osakenumerot',
        'assignment__label--share-serial': 'Osakesarja',
        'assignment__label--share-book': 'Osakekirja',
        'assignment__label--share-count': 'Osakemäärä',
        'assignment__label--share-registry': 'Osakekirjan sijainti',
        'assignment__label--flat-storey': 'Huoneiston kerros',
        'assignment__label--flat-number': 'Huoneiston numero',
        'assignment__label--flat-condition-classification': 'Kuntoluokitus',
        'assignment__label--flat-condition': 'Kunto',
        'assignment__label--flat-kitchen-equipment': 'Keittiön varusteet',
        'assignment__label--flat-kitchen': 'Keittiön lisätiedot',
        'assignment__label--flat-wc': 'WC',
        'assignment__label--livingroom': 'Olohuone',
        'assignment__label--bedrooms': 'Makuuhuoneet',
        'assignment__label--flat-bathrooms': 'Kylpyhuoneet',
        'assignment__label--flat-utility-room': 'Kodinhoitohuone',
        'assignment__label--flat-extra': 'Lisätietoja',
        'assignment__label--flat-extra_brochure': 'Lisätietoja (vain esite)',
        'assignment__label--foundation_type': 'Perustamistapa',
        'assignment__label--trade-bid-assignment': 'Tarjouskauppakohde',
        'assignment__label--trade-bid-target-debtprice': 'Tavoitehinta',
        'assignment__label--trade-bid-latest': 'Korkein voimassaoleva tarjous',
        'assignment__label--trade-bid-no-bids': 'Ei voimassaolevia tarjouksia',
        'assignment__label--source-channel': 'Hankintakanava',
        'assignment__label--contract-created': 'Toimeksiantosopimus on tehty',
        'assignment__label--salesprice': 'Myyntihinta',
        'assignment__label--salesprice-start': 'Lähtöhinta',
        'assignment__label--debtprice-goal': 'Velaton tavoitehinta',
        'assignment__label--targetprice-mortgage-debt': 'Panttikirjat',
        'assignment__label--targetprice-mortgage-debt-free': 'Vapaat panttikirjat',
        'assignment__label--targetprice-mortgage-debt-keeping': 'Panttikirjojen säilytys',
        'assignment__label--targetprice-stress-proof-date': 'Rasitustodistuksen pvm',
        'assignment__label--targetprice-property-tax': 'Kiinteistövero',
        'assignment__label--targetprice-water-total': 'Vesimaksu',
        'assignment__label--targetprice-cleaning': 'Puhtaanapito',
        'assignment__label--targetprice-warming': 'Lämmityskustannukset',
        'assignment__label--targetprices-total': 'Kustannukset yhteensä',
        'assignment__label--targetprice-oil-use': 'Öljynkulutus',
        'assignment__label--targetprice-electric-use': 'Lämmityskulut',
        'assignment__label--targetprice-electric-consumption': 'Sähkon kulutus',
        'assignment__label--debtpart': 'Velkaosuus',
        'assignment__label--debtprice-bid': 'Velaton lähtöhinta',
        'assignment__label--debtprice': 'Velaton myyntihinta',
        'assignment__label--debtpart-date': 'Velkaosuuden päiväys',
        'assignment__label--debtprice-final': 'Toteutunut velaton hinta',
        'assignment__label--targetprice-loanpayable': 'Laina poismaksettavissa',
        'assignment__label--targetprice-care': 'Hoitovastike',
        'assignment__label--targetprice-care-sq': 'Hoitovastike neliöltä',
        'assignment__label--targetprice-redemption-price': 'Osuus tontin lunastushinnasta',
        'assignment__label--targetprice-financing': 'Rahoitusvastike',
        'assignment__label--targetprice-repair': 'Korjausvastike',
        'assignment__label--targetprice-financing-sq': 'Rahoitusvastike neliöltä',
        'assignment__label--targetprice-repair-sq': 'Korjausvastike neliöltä',
        'assignment__label--housingtotalfee': 'Kokonaisvastike',
        'assignment__label--housingtotalfee-sq': 'Kokonaisvastike neliöltä',
        'assignment__label--housinglotrent': 'Tontinvuokravastike',
        'assignment__label--housinglotrent-sq': 'Tontinvuokravastike neliöltä',
        'assignment__label--targetprice-rent-per-month': 'Vuokratulo',
        'assignment__label--targetprice-other-payments': 'Muut maksut',
        'assignment__label--targetprice-other-buyers-payments': 'Muut ostajan maksut',
        'assignment__label--targetprice-water': 'Vesimaksu (hlö)',
        'assignment__label--targetprice-waterspec': 'Vesimaksun lisätiedot',
        'assignment__label--target-freedate-type': 'Vapautuminen',
        'assignment__label--target-freedate': 'Vapautumispäivä',
        'assignment__label--target-freedate-text': 'Vapautumisen lisätiedot',
        'assignment__label--target-lodger': 'Vuokralainen',
        'assignment__label--target-lodger-phone': 'Vuokralaisen puhelin',
        'assignment__label--target-lodgerlivedsince': 'Vuokralainen asunut lähtien',
        'assignment__label--target-revoking': 'Vuokrasuhteen  irtisanominen',
        'assignment__label--target-revoking-date': 'Vuokrasuhteen irtisanomisen pvm',
        'assignment__label--target-revoker': 'Irtisanoja',
        'assignment__label--target-holding-type': 'Omistusmuoto',
        'assignment__label--targetprice-payment-method': 'Maksutapa',
        'assignment__label--target-burdens': 'Rasitteet/oikeudet',
        'assignment__label--target-deed-restrictions': 'Saantorajoitteet',
        'assignment__label--certified-owner': 'Lainhuuto-oikeuden haltija',
        'assignment__label--acquisition-type-date': 'Saantotapa ja pvm',
        'assignment__label--target-modifications': 'Korjaukset',
        'assignment__label--target-extra': 'Lisätiedot',
        'assignment__label--target-services': 'Alueen palvelut',
        'assignment__label--target-schools': 'Koulut ja päiväkodit',
        'assignment__label--target-transport': 'Liikenneyhteydet',
        'assignment__label--property-planning': 'Kaavoitustilanne',
        'assignment__label--property-infosource': 'Kaavoituksen lisätiedot antaa',
        'assignment__label--planning-desc': 'Lisätietoja kaavoituksesta',
        'assignment__label--target-class': 'Kohdeluokka',
        'assignment__label--costlocation': 'Kust.paikka',
        'assignment__label--target-type': 'Kohdetyyppi',
        'assignment__label--target-details': 'Tyypin tarkennus',
        'assignment__label--energy-class': 'Energialuokka',
        'assignment__label--energy-certificate': 'Energiatodistus',
        'assignment__label--energy-certificate-desc': 'Energiatodistuksen lisätiedot',
        'assignment__label--data-visibility': 'Näkyvyys',
        'assignment__label--data-visibility-other': 'Muut julkaistavat palvelut',
        'assignment__label--oikotie': 'Oikotie',
        'assignment__label--oikotie-release': 'Julkaise Oikotiellä',
        'assignment__label--oikotie-order-text':
            'Teillä ei ole käytössä vielä Oikotien palvelua. Valitse Teille sopiva Oikotie-paketti ',
        'assignment__label--oikotie-order-link-text': ' täältä',
        'assignment__label--oikotie-info-text':
            'Ilmoittaminen Oikotiellä kannattaa! Tavoitamme yli 950 000 asunnonvaihdosta kiinnostunutta asiakasta joka viikko. Oikotiellä kohdeilmoituksesi näkyvät myös markkinan ainoassa mobiilisovelluksessa ilman lisäkustannuksia.',
        'assignment__label--oikotie-procurement-info-text':
            'HUOM! Mikäli olet syöttänyt saman ostotoimeksiannon käsin Oikotielle, niin käythän poistamassa ilmoituksen Oikotien yrityshallintapaneelista. Näin vältytte tuplailmoitukselta.',
        'assignment__label--oikotie-no-procurements':
            'Toimeksiannolla pitää olla voimassa ainakin yksi kohdehaku portaaleihin siirtoa varten.',
        'assignment__label--oikotie-info-text-marketing':
            'Hakutuloskorostuksen avulla saat lisänäkyvyyttä ilmoituksellesi Oikotiellä. Ilmoitus nousee hakutulosten kärkeen ja näkyy niissä korostettuna. Ilmoituksesta lähetetään myös ylimääräinen viesti asunnon etsijöille.',
        'assignment__label--oikotie-info-text-campaign':
            'Kohdesivun aitiopaikalla näkyvä nosto on erinomainen tapa kiinnittää asunnonetsijöiden huomio ja tarjota heille samalla lisäarvoa vaikkapa bloginoston muodossa. Voit käyttää kampanjanostoa myös kohteen ainutlaatuisuuden korostamiseen tai kohteen välittäjän esittelyyn, sillä tämä mainos ei varmasti jää näkemättä',
        'assignment__label--oikotie-step-text-campaign-1': 'Kuuluu Oikotien; Superpakettiin',
        'assignment__label--oikotie-step-text-campaign-2': 'Ostettavissa lisätuotteena Oikotien; Tehopakettiin',
        'assignment__label--oikotie-price-text-marketing': '31,45 € / 3 vuorokautta',
        'assignment__label--oikotie-price-text-campaign': '65-1300€ / kk',
        'assignment__label--oikotie-pricesuffix-text-marketing': '(alv 0%)',
        'assignment__label--oikotie-pricesuffix-text-campaign': '/ ketju',
        'assignment__label--oikotie-observation-text-campaign':
            'Huom. Hinta per toimipiste on 65€/kk, maksimilaskutus 1300€/kk/ketju.',
        'assignment__label--oikotie-view-ad': 'Esikatele ilmoitusta',
        'assignment__label--oikotie-add-visibility': 'Lisää näkyvyyttä kohteelle Oikotiellä',
        'assignment__label--oikotie-add-visibility-order': 'Osta lisänäkyvyyttä',
        'assignment__label--oikotie-add-visibility-valid-1': 'Lisänäkyvyys on voimassa ',
        'assignment__label--oikotie-add-visibility-valid-2': ' asti.',
        'assignment__label--oikotie-buy-campaign': 'Kampanjanosto kohdesivulla Oikotiellä',
        'assignment__label--oikotie-campaign-start-date': 'Aloituspäivämäärä',
        'assignment__label--oikotie-campaign-start-time': 'Aloitusaika',
        'assignment__label--oikotie-campaign-heading': 'Kampanjan otsikko',
        'assignment__label--oikotie-campaign-link-url': 'Linkin URL',
        'assignment__label--oikotie-campaign-link-text': 'Linkin teksti',
        'assignment__label--oikotie-campaign-link-color': 'Linkin väri',
        'assignment__label--oikotie-campaign-picture-title': 'Kampanjakuva',
        'assignment__label--oikotie-campaign-picture-info': 'Voit halutessasi vaihtaa kuvan kampanjalle.',
        'assignment__label--oikotie-campaign-picture-info2': 'Ladattu kuva:',
        'assignment__label--oikotie-campaign-picture-button': 'Lataa kuva',
        'assignment__label--oikotie-campaign-buy': 'Tee kampanjanosto',
        'assignment__label--oikotie-campaign-remove': 'Poista kampanja käytöstä',
        'assignment__label--oikotie-order': 'Oikotie-sopimus',
        'assignment__label--etuovi': 'Etuovi',
        'assignment__label--kauppalehti': 'Kauppalehti',
        'assignment__label--toimitilatfi': 'Toimitilat',
        'assignment__label--sales-brochure': 'Myyntiesite',
        'assignment__label--window-display': 'Ikkunakortti',
        'assignment__label--window-display-marketing': 'Ikkunanäyttö',
        'assignment__label--website': 'Kotisivut',
        'assignment__label--heading': 'Otsikko',
        'assignment__label--internet-text': 'Esittelyteksti',
        'assignment__label--brochure-text': 'Esitteen esittelyteksti',
        'assignment__label--windowcard-text': 'Ikkunakortin esittelyteksti',
        'assignment__label--virtual-presentation': 'Virtuaaliesittely',
        'assignment__label--videoclip': 'Videoleike',
        'assignment__label--marketing-link': 'Uudiskohteen linkki',
        'assignment__label--construction-stage': 'Rak.vaihe',
        'assignment__label--flat-count': 'Asuntolkm',
        'assignment__label--office': 'Toimisto',
        'assignment__label--description': 'Tilakuvaus',
        'assignment__label--description-abr': 'Kuvaus',
        'assignment__label--customer': 'Asiakas',
        'assignment__label--dealer': 'Välittäjä',
        'assignment__label--person': 'Hankkija #1',
        'assignment__label--person2': 'Hankkija #2',
        'assignment__label--person3': 'Hoitaja',
        'assignment__label--person4': 'Myyjä',
        'assignment__label--person5': 'Myyjä #2',
        'assignment__label--person-salesmen': 'Myyjät',
        'assignment__label--split1': 'Hankkija #1 jako',
        'assignment__label--split2': 'Hankkija #2 jako',
        'assignment__label--split3': 'Hoitaja jako',
        'assignment__label--split4': 'Myyjä jako',
        'assignment__label--validity': 'Voimassaolo',
        'assignment__label--contract-date': 'Sopimuksen päiväys',
        'assignment__label--commission': 'Välityspalkkio',
        'assignment__label--commission-total': 'Välityspalkkio yhteensä',
        'assignment__label--housing': 'Taloyhtiö',
        'assignment__label--target-new': 'Uudiskohde',
        'assignment__label--target-holiday': 'Lomakohde',
        'assignment__label--validity-start': 'Voimassaolo alkaa',
        'assignment__label--validity-end': 'Voimassaolo päättyy',
        'assignment__label--validity-continues': 'Sopimus jatkuu 1kk kerrallaan',
        'assignment__label--internal-memo': 'Lisätiedot sisäiseen käyttöön',
        'assignment__label--buyer-customer': 'Ostaja',
        'assignment__label--buyer-customer-2': 'Ostaja #2',
        'assignment__label--buyer-customers': 'Ostajat',
        'assignment__label--diary-salesprice': 'Toteutunut myyntihinta',
        'assignment__label--deal-downpayment-amount': 'Käsiraha',
        'assignment__label--deal-downpayment-date': 'Käsirahan maksupvm',
        'assignment__label--deal-on-deal-amount': 'Kaupanteossa maksettava',
        'assignment__label--deal-date': 'Kaupantekopvm',
        'assignment__label--deal-final-payment': 'Kauppahinnan loppuerä',
        'assignment__label--deal-moving-date': 'Muuttopvm',
        'assignment__label--deal-last-payment': 'Loppukauppahinnan maksuehdot',
        'assignment__label--deal-possession-transfers': 'Hallintaoikeus siirtyy',
        'assignment__label--deal-ownership-transfer-date': 'Omistusoikeuden siirtymispvm',
        'assignment__label--deed-edit': 'Muokkaa kauppakirjaa',
        'assignment__label--deed-create': 'Luo kauppakirja',
        'assignment__label--deed-show': 'Näytä kauppakirja',
        'assignment__label--deed-forward': 'Viimeistele kauppa',
        'assignment__label--deed-to-archive': 'arkistoon',
        'assignment__label--deed-in-archive-t1': 'Mikäli kauppakirjaa ei vielä ole ',
        'assignment__label--deed-in-archive': 'arkistoitu',
        'assignment__label--deed-in-archive-t2': ' niin avaa se alta',
        'assignment__label--deed-with-old': 'Kohde viety myydyksi PDX:n vanhaa kauppakirjaa käyttäen.',
        'assignment__label--deed-with-none': 'Kohde viety myydyksi ilman että kauppakirjaa on tehty PDX:n kautta.',
        'assignment__label--deed-with-none-text': 'Katso tai lisää kauppakirja ',
        'assignment__label--deed-with-new-text': 'Kauppakirja arkistoitu ',
        'assignment__label--deal-type': 'Kaupan tyyppi',
        'assignment__label--cash-deal': 'Käteiskauppa',
        'assignment__label--installment-payment': 'Osamaksukauppa',
        'assignment__label--ownership-transfers': 'Omistusoikeus siirtyy',
        'assignment__label--deal-fee-substract': 'Palkkion vähennys',
        'assignment__label--deal-fee-substract-reason': 'Palkkion vähennyksen syy',
        'assignment__label--flat-total-storeys': 'Kerrosluku',
        'assignment__label--property-usagetype': 'Tilat/pinta-alat/muut rakennukset',
        'assignment__label--property-type': 'Kiinteistön tyyppi',
        'assignment__label--property-city-type': 'Kunta vai kaupunki',
        'assignment__label--unseparated-parcel': 'Määräala',
        'assignment__label--property-identifier': 'Kiinteistötunnus',
        'assignment__label--property-registration-date': 'Kiinteistörekisterin pvm',
        'assignment__label--property-city': 'Kunta/kaupunki',
        'assignment__label--property-district': 'Kylä/kaupunginosa',
        'assignment__label--property-name': 'Tilan nimi',
        'assignment__label--property-reg-no': 'Rekisterinumero',
        'assignment__label--property-block': 'Kortteli/tila',
        'assignment__label--property-site-number': 'Tilan numero',
        'assignment__label--property-lot-number': 'Tontin numero',
        'assignment__label--property-rent-ends': 'Vuokra-aika päättyy',
        'assignment__label--property-rent': 'Vuokra',
        'assignment__label--property-tenancy-handover': 'Vuokraoikeuden siirto',
        'assignment__label--property-landscape': 'Maasto',
        'assignment__label--property-soil': 'Maaperä/kasvusto',
        'assignment__label--property-limitation': 'Alueen käyttörajoitukset',
        'assignment__label--property-building-right': 'Rakennusoikeus',
        'assignment__label--property-public-utility': 'Kunnallistekniikka',
        'assignment__label--property-water': 'Käyttövesi',
        'assignment__label--property-sewage': 'Viemäri',
        'assignment__label--property-access': 'Liittymät (tontties.)',
        'assignment__label--property-extra': 'Lisätiedot',
        'assignment__label--tax-bailor': 'Luovuttaja',
        'assignment__label--tax-receiver': 'Luovutuksensaaja',
        'assignment__label--tax-idcode': 'Hetu/Y-tunnus',
        'assignment__label--tax-share': 'Osuus',
        'assignment__label--tax-share-error': 'Virheellinen murtoluku',
        'assignment__label--tax-first-time-buyer': 'Ensiasunnon ostaja',
        'assignment__label--bank-transfer': 'Tilisiirto',
        'assignment__label--tax-is-moving': 'Muuttaa mukana',
        'assignment__label--tax-capital-transfer-relative': 'Sukulaisluovutus',
        'assignment__label--tax-capital-transfer-dealer-trade': 'Välittäjäkauppa',
        'assignment__label--tax-capital-transfer-company-partner':
            'Oliko ostaja jo ennen kauppaa osakkaana tai yhtiömiehenä myyjäyhtiössä tai onko ostaja myyjäyhtiön osakkaan tai yhtiömiehen vanhempi, puoliso tai lapsi?',
        'assignment__label--tax-capital-transfer-seller-company-partner':
            'Onko myyjä ostajayhtiön osakas tai yhtiömies tai ostajayhtiön osakkaan tai yhtiömiehen vanhempi, puoliso tai lapsi?',
        'assignment__label--tax-capital-transfer-internal-company-partner':
            'Onko kyseessä yhtiön ja sen osakkaan tai yhtiömiehen välinen luovutus tai konsernin sisäinen luovutus?',
        'assignment__label--capital-transfer-tax-percent': 'Varainsiirtoveroprosentti',
        'assignment__label--capital-transfer-tax': 'Varainsiirtovero',
        'assignment__label--capital-transfer-debtpart-tax': 'Varainsiirtovero yhtiölainaosuudesta',
        'assignment__label--capital-transfer-payday': 'Eräpäivä',
        'assignment__label--capital-transfer-regular-flat-date': 'Vakituinen asukas alkaen',
        'assignment__label--capital-transfer-flat-area': 'Asuntona käytettävä osuus (m²)',
        'assignment__label--capital-transfer-flat-percent': 'Asuntona käytettävä osuus (%)',
        'assignment__label--transfer-tax-form-date': 'Ilmoituksen pvm',
        'assignment__label--tax-payable': 'Maksettava osuus',
        'assignment__label--tax-paid-comfirmation': 'Vakuutan, että kaikkien ostajien varainsiirtoverot on maksettu',
        'assignment__label--status-change-missing-fields': 'Seuraavat kentät tulee täyttää',
        'assignment__label--price-not-be-updated': 'Alkamispäivämäärä on tulevaisuudessa, jolloin hintaa ei päivitetä.',
        'assignment__label--remember-to-update-price':
            'Muista vaihtaa myyntihinta PDX:ssä $DATE, kun uusi hinta astuu voimaan.',
        'assignment__commission--fee-type': 'Palkkion tyyppi',
        'assignment__commission--fee-type-percent': 'Prosenttiosuus',
        'assignment__commission--fee-type-fixed': 'Kiinteä alkaen',
        'assignment__commission--fee-percent': 'Sovittu palkkioprosentti',
        'assignment__commission--fee-incl-tax': 'Sis. ALV $0%',
        'assignment__commission--fee-from-debt': 'Velattomasta hinnasta',
        'assignment__commission--min-fixed-fee': 'Kiinteä palkkio vähintään',
        'assignment__commission--fee-substract': 'Palkkiosta vähennettävä €',
        'assignment__commission--fee-substract-reason': 'Palkkion vähennyksen syy',
        'assignment__commission--fee-with-tax': 'Palkkio (ALV $0%) €',
        'assignment__commission--fee-with-no-tax': 'Palkkio (ALV 0%) €',
        'assignment__commission--document-fees': 'Asiakirjakulut (ALV 24 %) €',
        'assignment__commission--fee-payment-date': 'Palkkion maksupäivä',
        'assignment__commission--fee-details': 'Palkkion yksityiskohdat',
        'assignment__search-units': 'Kohdetta',
        'assignment__status--draft': 'Kesken',
        'assignment__status--premarketing': 'Enn.markk',
        'assignment__status--on-sale': 'Myynnissä',
        'assignment__status--free': 'Vapaa',
        'assignment__status--sold': 'Myyty',
        'assignment__status--max-amount-reached': 'Maksimimäärä saavutettu.',
        'assignment__status--in-progress': 'Hankinnassa',
        'assignment__status--found': 'Hankittu',
        'assignment__target-type--flats': 'Asunnot',
        'assignment__target-type--offices': 'Liiketilat',
        'assignment__target-type--storages': 'Varastotilat',
        'assignment__target-type--carslots': 'Autopaikat',
        'assignment__target-type--block': 'Kerrostalo',
        'assignment__target-type--rowhouse': 'Rivitalo',
        'assignment__target-type--semi': 'Paritalo',
        'assignment__target-type--carslot': 'Autopaikka',
        'assignment__asset-label': 'Lisätyn osakesarjan omaisuuslaji VSV-ilmoitukseen',
        'assignment__asset-type-apartment': 'Asunto',
        'assignment__asset-type-garage': 'Autopaikka, varasto, liikehuoneisto tai muu tila',
        'assignment__asset-type-time-share': 'Viikko-osake tai aikaosuusosake',
        'assignment__flat_share_add-button': 'Lisää osakesarja',
        'assignment__flat_share_info-text-debt':
            'PDX ei tue tilannetta, jossa lisäosakesarjoilla on velkaosuutta. (Tee uusi toimeksianto)',
        'assignment__flat_share_info-text':
            'Ensimmäinen osakesarja (yllä oleva kenttä) on oletuksena veroton ensiasuntoa ostettaessa.',
        'assignment__flat_share_no-tax-text': 'on myös veroton osakesarja ensiasuntoa ostaessa',
        'assignment__action--fill-persons': 'Esitäytä henkilöt',
        'assignment__action--count-fee': 'Laske palkkio',
        'assignment__action--add-new-housing': 'Lisää uusi taloyhtiö',
        'assignment__action--cancel-new-housing': 'Peru taloyhtion lisäys',
        'assignment__action--send-capital-transfer-form': 'Lähetä varainsiirtoveroilmoitus',
        'assignment__action--send-capital-transfer-form-alternate': 'Lähetä korvaava VS-veroilmoitus',
        'assignment__action--do-capital-transfer-payment': 'Tulosta tilisiirrot',
        'assignment__action--create-new': 'Lisää uusi toimeksianto',
        'assignment__action--finalize-sale': 'Päätä kauppaan',
        'assignment__action--invoice-fee': 'Tee palkkiolasku',
        'assignment__action--view-ad': 'Katso ilmoitus',
        'assignment__action--status-incomplete-sold': 'Peru kauppa',
        'assignment__action--status-incomplete-terminated': 'Palauta irtisanottu',
        'assignment__action--status-sale': 'Merkitse myytäväksi',
        'assignment__action--status-in-search': 'Merkitse hankintaan',
        'assignment__action--status-reserved': 'Merkitse varatuksi',
        'assignment__action--status-target-found': 'Kohde löytynyt',
        'assignment__action--status-sale-reserved': 'Palauta myyntiin',
        'assignment__action--status-return-to-searching': 'Palauta hankintaan',
        'assignment__action--status-terminated': 'Irtisano',
        'assignment__action--extend-contract': 'Jatka sopimusta',
        'assignment__action--duplicate': 'Kopioi uudeksi',
        'assignment__error--fee-splits-mismatch-alert': 'Palkkiojakoprosenttien summa ei ole 100%.',
        'assignment__error--fee-data-missing': 'Täytä palkkiotiedot',
        'assignment__error--seller-missing': 'Lisää myyjä',
        'assignment__error--illegal-deal-participant': 'Toimeksiantaja ei voi olla myös ostaja tai myyjä.',
        'assignment__error--illegal-tax-participant': 'Luovutuksensaaja ei voi olla myös luovuttaja.',
        'assignment__error--malformed-fraction-number': 'Virheellinen murtoluku syötetty.',
        'housing__heading--contract-info': 'Kauppakirjan tiedot',
        'housing__label--flat-reservations': 'Varatut / Myydyt / Yhteensä',
        'housing__search-unit': 'Yhtiötä',
        'brochure__heading--settings': 'Esitteen asetukset',
        'brochure__heading--create': 'Luo esite toimeksiannosta',
        'brochure__label--printer': 'Esitteen tulostaja',
        'brochure__label--template': 'Esitepohja',
        'brochure__label--compressible': 'Tiivis taitto',
        'brochure__text--page': 'Sivu',
        'brochure__text--info': 'Kohteen tiedot',
        'brochure__text--infocon': 'Kohteen tiedot (jatkuu)',
        'brochure__text--floorplan': 'Pohjakuva',
        'brochure__text--footer': 'Alatunniste',
        'brochure__text--logo': 'Logo',
        'brochure__text--main-image': 'Pääkuva',
        'brochure__text--basic-info': 'Perustiedot',
        'brochure__text--description': 'Esittelyteksti',
        'brochure__text--target-image': 'Kohdekuva',
        'brochure__select-option--empty-cover': 'tyhjä kansilehti',
        'brochure__select-option--photo': 'kuva',
        'displaybrochure__label--demonstrator': 'Esittelijä',
        'displaybrochure__label--nodemonstrator': 'Ei esittelijää',
        'displaybrochure__heading--config': 'Ikkunakortin asetukset',
        'displaybrochure__heading--create': 'Luo ikkunakortti toimeksiannosta',
        'pricelist__heading--settings': 'Hinnaston asetukset',
        'pricelist__heading--fields': 'Hinnaston kentät',
        'pricelist__heading--extra': 'Lisävalinnat',
        'pricelist__heading--date': 'Hinnaston päiväys',
        'pricelist__settings--show-salesprices': 'Näytä myytyjen hinnat',
        'pricelist__settings--show-salesprices-reserved': 'Näytä varattujen hinnat',
        'pricelist__settings--hide-sold': 'Piilota myydyt',
        'pricelist__settings--show-exact-status': 'Näytä tarkka varaustilanne',
        'widgets__title--yield-multi': 'Tulos 6kk / € (alv 0%)',
        'widgets__title--yield-volume': 'Tulos 6kk / kpl',
        'widgets__title--yield-current': 'Tulos nyt (alv 0%)',
        'widgets__title--news': 'Tiedotteet',
        'widgets__title--leads': 'Liidit',
        'widgets__title--assignments': 'Toimeksiannot',
        'widgets__title--customer-actions': 'Asiaakaan lisäys',
        'widget__lead--actions-interest': 'Kohdekiinnostus',
        'widget__lead--actions-contact': 'Yhteystiedot',
        'widget__lead--actions-transfer': 'Siirrä käyttäjälle',
        'widget__lead--label-transfer': 'Siirrä',
        'widget__lead--label-transfer-done': 'Siirretty',
        'widget__yield--result-label': 'Tavoitteesta saavutettu',
        'widget__yield--goal': 'Tavoite',
        'widget__yield--result': 'Tulos',
        'widget__yield--reserve': 'Varaukset',
        'person__label--show-deactivated': 'Näytä myös poistetut',
        'person__label--ssn-error': 'tunnus löytyy jo asiakasrekisteristä',
        'person__action--create-new': 'Luo uusi henkilö',
        'archive__heading--add-file': 'Tiedoston lisäys',
        'archive__heading--digital-signing': 'Sähköinen allekirjoitus',
        'archive__heading--signers': 'Allekirjoittajat',
        'archive__heading--send-files-email': 'Lähetä tiedostoja sähköpostitse',
        'archive__label--enable-inhouse': 'Lisää välittäjan edustaja allekirjoittajaksi',
        'archive__label--signing-inhouse-person': 'Allekirjoittava välittäjän edustaja',
        'archive__label--add-signer': 'Lisää allekirjoittaja',
        'archive__label--signed-file': 'Sähköisesti allekirjoitettava tiedosto',
        'archive__label--inviter': 'Kutsuja',
        'archive__label--no-files': 'Ei tiedostoja',
        'archive__label--next-page': 'Seuraava sivu',
        'archive__label--previous-page': 'Edellinen sivu',
        'archive__label--rows-per-page': 'Rivejä sivulla',
        'archive__label--files-to-send': 'Lähetettävät tiedostot',
        'archive__label--sender-name': 'Lähettäjän nimi',
        'archive__label--sender-email': 'Lähettäjän sähköpostiosoite',
        'archive__label--receiver-email': 'Vastaanottajan sähköpostiosoite',
        'archive__label--email-cc': 'Lähetä kopio viestistä myös lähettäjän sähköpostiosoitteeseen.',
        'archive__label--message': 'Viesti',
        'archive__action--sign': 'Allekirjoita',
        'archive__action--send-to-signing': 'Lähetä allekirjoitettavaksi',
        'archive__action--cancel-signing': 'Peruuta allekirjoitus',
        'archive__action--signing-waiting-sending': 'odottaa kutsun lähetystä',
        'archive__action--signing-waiting-signing': 'odottaa allekirjoitusta',
        'archive__action--signing-signed': 'allekirjoitettu',
        'archive__action--signing-canceled': 'peruutettu',
        'archive__action--given-information': 'Annettu salasana ja osoite',
        'tooltip__assignment--site-area':
            'Tontin pinta-ala syötetään aina neliömetreinä. Viereisessä kentässä esitetään pinta-ala hehtaareina.',
        'tooltip__assignment--asbestos-survey':
            'Ennen vuotta 1994 rakennetuissa kohteissa on ilmoitettava onko asbestikartoitus tehty.',
        'tooltip__assignment--trade-bid-process': 'Tarjosukauppaprosessi tapahtuu `Tarjouskauppa`-sivulla.',
        'tooltip__assignment--condition-classification': `etuovi.com:
"Erinomainen" = "Hyvä",
"Huono" = "Välttävä"`,
        'tooltip__assignment--flat-extra-brochure': 'Muuta kauppaan kuuluvaa. Esimerkiksi ilmalämpöpumppu.',
        'tooltip__assignment--energy-certificate-desc': 'Lisätietoja energiatodistuksesta tai sen tarpeettomuudesta.',
        'tooltip__person--image':
            'Huomioi seuraavat asiat profiilikuvissa portaaleja varten:\n' +
            '- Kuvassa tulisi olla tarpeeksi taustaa näkyvissä henkilön ympärillä\n' +
            '- Kuvanlaatu kannattaa olla mahdollisimman hyvä palveluun lähetettävässä kuvassa\n' +
            '- Kasvojen ympärillä on hyvä olla vähän tyhjää tilaa',
        'tooltip__assignment--save-before-transfer': 'Tallenna dokumentti tilisiirtotietojen luomiseksi, kiitos.',
        'tooltip__assignment--sales-tax': `Huom!

Jos ulkomaalainen ostaja ei ole Verohallinnon rekisterissä, täytyy ostajan viitenumeron saamiseksi rekisteröityä asiakkaaksi Verohallintoon ennen kauppaa.

Rekisteröinti käsitellään Verohallinnossa korkealla prioriteetilla, mutta asia on hoidettava hyvissä ajoin ennen kaupantekoa.

Ensiasunnon ostajan omistusosuus on aina vähintään puolet. Jos ostaja on ilmoittanut kyseessä olevan ensiasunnon hankinta ja vakuuttanut, että ehdot täyttyvät, niin voidaan "Ensiasunnon ostaja" -valinta asettaa.

Ensiasunnon ostajan tulee olla kaupantekohetkellä vähintään 18 ja enintään 39 vuotta vanha.`,
        'assignment__placeholder--tax-share': 'Syötä osuus murtolukuna eli esim. 1/2',
        'assignment__dias_introduction-p1':
            'Digitaalisella asunto-osakekaupalla tarkoitetaan DIAS-alustan avulla tapahtuvaa asuntokauppaa.',
        'assignment__dias_introduction-p2':
            'Digitaalinen asunto-osakekauppa on entistä joustavampi ja nopeampi tapa kaupankäyntiin.',
        'assignment__dias_introduction-p3': 'Kaupankäynti ei ole enää aika- tai paikkasidonnaista.',
        assignment__dias_link_text: 'Tutustu tarkemmin digitaaliseen asuntokauppaan',
        'person__heading--change-password': 'Vaihda salasana',
        'person__title--person-information': 'Henkilöstö',
        'person__title--person-basic-information': 'Henkilön perustiedot',
        'person__title--person-extra-information': 'Henkilön lisätiedot',
        'person__title--goals': 'Tavoitteet',
        'person__title--rights': 'Käyttäjätilin tiedot',
        'person__label--username': 'Käyttäjätunnus',
        'person__label--password': 'Salasana',
        'person__label--premises-dealer': 'Toimitilavälittäjä',
        'person__label--premises-dealer-text':
            'Toimitilavälittäjän oletetaan ensisijaisesti välittävän toimitiloja. Tämä otetaan palvelussa huomioon.',
        'person__label--profile-picture': 'Profiilikuva',
        'person__label--profile-picture-text': 'Kuva asuntoportaaleja varten.',
        'person__label--upload-picture': 'Lähetä kuva',
        'person__label--goals-text-p1':
            'Tässä kohdassa määritetään käyttäjäkohtaiset myyntitavoitteet. Tavoitteet merkitään euromäärinä tai kappaleina tuleville kuukausille.',
        'person__label--goals-text-p2':
            'Ajankohdat voivat olla yhdestä kuukaudesta koko vuodelle ja niitä voidaan tarkistaa tai muokata vuoden aikana.',
        'person__label--intro-text': 'Esittelyteksti',
        'person__label--intro-video': 'Esittelyvideo',
        'person__label--visible': 'Näkyy kotisivuilla',
        'person__label--on-vacation': 'Lomalla',
        'person__label--receive-leads': 'Vastaanotan liidejä',
        'person__label--billed': 'Veloitetaan',
        'person__label--domains': 'Toimialueet',
        'person__label--deactivated': 'Poista käytöstä',
        'person__label--total-goal': 'Kokonaistavoite',
        'person__action--change-password': 'Vaihda salasana',
        'person__action--old-password': 'Nykyinen salasana',
        'person__action--new-password': 'Uusi salasana',
        'person__action--new-password-2': 'Salasana toistettuna',
        'person__error--password-mismatch': 'Salasanat eivät täsmää',
        'person__error--password-old': 'Nykyinen salasana on virheellinen',
        'person__error--password-new-length-min': 'Uuden salasanan tulee olla vähintään $0 merkkiä pitkä',
        'person__error--password-new-length-max': 'Uuden salasanan tulee olla korkeitaan $0 merkkiä pitkä',
        'assignment__text--transfer-tax-sent-not-current':
            'Ilmoitusta on muokattu, mutta muutettua ilmoitusta ei ole lähetetty. Painamalla "Lähetä korvaava ilmoitus" -nappulaa voit lähettää muokatun ilmoituksen. Aloita klikkaamalla "Muokkaa"-painiketta.',
        'assignment__text--transfer-tax-sent':
            'Varainsiirtoveroilmoitus on lähetetty. Mikäli haluat muokata ilmoitusta, klikkaa "Muokkaa"-painiketta',
        'infobox__label--brochure-archive':
            'Huomaathan, että tulostettu esite lisätään automaattisesti Arkistoon. Tulosteversiot ovat nähtävissä ja sähköpostitse lähetettävissä Arkistossa.',
        'infobox__label--pricelist-archive':
            'Huomaathan, että tulostettu hinnasto lisätään automaattisesti Arkistoon. Uusimman tulostetun hinnaston voit lähettää Arkistosta sähköpostitse.',
        'infobox__label--windowcard-archive':
            'Huomaathan, että tulostettu ikkunakortti lisätään automaattisesti Arkistoon. Tulosteversiot ovat nähtävissä Arkistossa.',
        login__label: 'Sisäänkirjautuminen',
        'pep__label--top-text':
            'PEP-kysely on yhdistetty kiinteistön ja osakkeen sähköiseen toimeksiantolomakkeeseen. Voit tehdä muut PEP-kyselyt tästä.',
        'login__label--title': 'Sisäänkirjautuminen',
        'login__label--login-button': 'Kirjaudu',
        'login__label--missing-fields': 'Täytä käyttäjätunnus- ja salasanakentät',
        'login__label--password-length':
            'Valitettavasti salasanasi on liian lyhyt. Käytä unohditko salasanaasi vaihtaaksesi sen ja toimi sähköpostiin tulleiden ohjeiden mukaan.',
        'login__label--login-error': 'Sisäänkirjautuminen epäonnistui',
        'login__label--did-you-forgot-password': 'Unohditko salasanasi?',
        'login__label--email-error': 'Sähköpostin lähetys epäonnistui',
        'login__label--forgot-password-email-sent':
            'Sähköposti salasanan vaihtamiseen lähetetty. (Huom. muista katsoa myös roskaposti/muut filtterit)',
        'login__label--hash-error':
            'Linkki virheellinen tai vanhentunut. Tilaa uusi linkki kirjautumissivun "unohditko salasanasi"-kohdasta.',
        'login__label--new-password-sent': 'Uusi salasana on lähetetty sinulle sähköpostitse!',
        'login__label--login-to-pdx': 'Kirjaudu PDX:ään tästä',
        'login__label--sent': 'Lähetetty',
        'login__label--logout-error': 'Uloskirjautuminen epäonnistui. Virkistä selain ja yritä uudelleen.',
    },
    en: {
        'ui__navi--dashboard': 'Dashboard',
        'ui__navi--customers': 'Customers',
        'ui__navi--assignments': 'Assignments',
        'ui__navi--marketing': 'Marketing',
        'ui__navi--intranet': 'Intranet',
        'ui__navi--reports': 'Reports',
        'ui__navi--settings': 'Settings',
        'ui__navi--housing': 'Housing',
        'ui__navi--help': 'Help',
        'ui__navi--support': 'Support',
        'ui__navi--extranet': 'Extranet',
        'ui__navi--back': 'Back',
        'ui__navi--marketing-showing-times': 'Showing times',
        'ui__navi--marketing-markets': 'Markets',
        'ui__navi--marketing-magazines': 'Magazines',
        'ui__navi--customers-search': 'Customer search',
        'ui__navi--customers-leads': 'Customer leads',
        'ui__navi--assignments-sales': 'Sales assignments',
        'ui__navi--assignments-sale': 'Sale assignments',
        'ui__navi--assignments-rent': 'Rent assignments',
        'ui__navi--assignments-acquisition': 'Acquisition assignments',
        'ui__navi--assignments-acquisition-single': 'Acquisition',
        'ui__navi--assignments-new-acquisition': 'TBD',
        'ui__navi--reports-power-bi': 'Power BI',
        'ui__navi--reports-rent-list': 'Rent list',
        'ui__navi--reports-assignments-acquisition': 'Acquisition assignments',
        'ui__navi--reports-terminated-targets': 'Terminated targets',
        'ui__navi--reports-sales-list': 'Sales list',
        'ui__navi--reports-journal': 'Journal',
        'ui__navi--reports-brokerage': 'Brokerage',
        'ui__navi--reports-sold-targets': 'Sold targets',
        'ui__navi--reports-events-by-customer': 'Events by customer',
        'ui__navi--reports-acquisition-channels': 'Acquisition channels',
        'ui__navi--reports-bills': 'Bills',
        'ui__navi--reports-sold-targets-by-type': 'Sold targets by type',
        'ui__navi--settings-news': 'News',
        'ui__navi--settings-offices-admin': 'Offices (admin)',
        'ui__navi--settings-offices': 'Offices',
        'ui__navi--settings-staff-admin': 'Staff (admin)',
        'ui__navi--settings-staff': 'Staff',
        'ui__navi--settings-user-groups': 'User groups',
        'ui__navi--settings-companies': 'Companies',
        'ui__navi--settings-rescission': 'Rescission',
        'ui__navi--settings-bills': 'Bills',
        'ui__navi--settings-magazines': 'Magazines',
        'ui__navi--settings-about': 'About PDX',
        'ui__navi--settings-environment': 'Usage environment',
        'ui__navi--settings-help': 'Help',
        'ui__navi--settings-personal-info': 'Personal info',
        'ui__navi--settings-support': 'Support',
        'ui__navi--settings-website-update': 'Website update',
        'ui__navi--settings-jobs': 'Jobs',
        'ui__navi--settings-intranet': 'Intranet',
        'ui__navi--settings-feedback': 'PDX+ feedback',
        'ui__navi--settings-export': 'Data export',
        'ui__navi--intranet-internal-news': 'Internal news',
        'ui__navi--intranet-calendar': 'Event calendar',
        'ui__navi--intranet-offices': 'Our offices',
        'ui__navi--intranet-person-search': 'Person search',
        'ui__navi--intranet-legal': 'Legal information',
        'ui__navi--intranet-document-archive': 'Document archive',
        'ui__months-1': 'January',
        'ui__months-2': 'February',
        'ui__months-3': 'March',
        'ui__months-4': 'April',
        'ui__months-5': 'May',
        'ui__months-6': 'June',
        'ui__months-7': 'July',
        'ui__months-8': 'August',
        'ui__months-9': 'September',
        'ui__months-10': 'October',
        'ui__months-11': 'November',
        'ui__months-12': 'December',
        'ui__general--close': 'Close',
        'ui__general--loading': 'Loading',
        'ui__general--edit': 'Edit',
        'ui__general--edit-stop': 'Stop editing',
        'ui__general--add': 'Add',
        'ui__general--assign': 'Assign',
        'ui__general--edit-content': 'Edit content',
        'ui__general--select-user': 'Select user',
        'ui__general--select': 'Select',
        'ui__general--type': 'Type',
        'ui__general--status': 'Status',
        'ui__general--logout': 'Log out',
        'ui__general--save': 'Save',
        'ui__general--send': 'Send',
        'ui__general--print': 'Print',
        'ui__general--save-and-print': 'Save and Print',
        'ui__general--processing': 'Processing',
        'ui__general--page': 'Page',
        'ui__general--search-units': 'Items',
        'ui__general--documents': 'Documents',
        'ui__general--actions': 'Actions',
        'ui__general--alv': 'Sales tax',
        'ui__general--total': 'Total all',
        'ui__general--reward': 'Reward',
        'ui__general--file': 'File',
        'ui__general--files': 'Files',
        'ui__general--browse': 'Browse',
        'ui__general--filename': 'File name',
        'ui__general--file-type': 'File type',
        'ui__general--file-to-be-added': 'File',
        'ui__general--description': 'Description',
        'ui__general--created': 'Created',
        'ui__general--created-file': 'Created',
        'ui__general--download': 'Download',
        'ui__general--signing-start': 'Start signing phase',
        'ui__general--sign': 'Sign document',
        'ui__general--sign-status': 'Signing status',
        'ui__general--sign-done': 'Signing done',
        'ui__general--sign-canceled': 'Signing cancelled',
        'ui__general--delete': 'Delete',
        'ui__general--cancel': 'Cancel',
        'ui__general--canceled': 'Canceled',
        'ui__general--in-signing': 'In signing',
        'ui__general--signed': 'Signed',
        'ui__general--new': 'New',
        'ui__general--proceed': 'Continue',
        'ui__general--last-valid-date': 'Valid until',
        'ui__general--active': 'Active',
        'ui__general--rejected': 'Rejected',
        'ui__general--copy-info': 'Please copy information',
        'ui__general--url': 'Address',
        'ui__general--password': 'Password',
        'ui__general--selected': 'Selected',
        'ui__general--not-selected': 'Not selected',
        'ui__general--normal': 'Normal',
        'ui__general--custom': 'Custom',
        'ui__general--email': 'Email address',
        'ui__general--email-or-username': 'Email address or username',
        'ui__general--list-customer-name': 'Customer name',
        'ui__action--refresh-view': 'Refresh view',
        'ui__action--add-pep': 'Add PEP-questionnaire',
        'ui__action--add-file': 'Add file',
        'ui__action--view-file': 'View file',
        'ui__action--download-file': 'Download file',
        'ui__action--open-details': 'Open details',
        'ui__action--download-files': 'Download files',
        'ui__action--send-files-by-email': 'Send files by email',
        'ui__action--delete-files': 'Delete files',
        'ui__action--try-again': 'Try again',
        'ui__action--add-procurement': 'TBD',
        'ui__validation--form-errors': 'Please fill the fields marked by star (*) first',
        'ui__validation--no-customers': 'At least one mandator is required',
        'ui__validation--required-field': 'Required field',
        'ui__validation--input-short': 'Input is too short',
        'ui__validation--input-alphanumeric': 'Don´t use spaces or non-alphanumerics',
        'ui__validation--input-short-n': 'Input is too short - at least $0 chars is required',
        'ui__validation--input-long-n': 'Input is too long - max $0 chars is allowed',
        'ui__validation--input-email': 'Input is not email',
        'ui__validation--number-pos': 'Positive number required',
        'ui__validation--number-nan': 'Number required',
        'ui__validation--number-more-than': 'Value must be more than $0',
        'ui__validation--unsupported-file-type': 'Unsupported file type.',
        'ui__validation--signer-n-name': 'Signer #$0 name',
        'ui__validation--signer-n-idcode': 'Signer #$0 identifier',
        'ui__validation--signer-n-email': 'Signer #$0 email',
        'ui__validation--person-idcode-invalid': 'Id code is invalid',
        'ui__validation--tax-deal-date': 'Missing deal date',
        'ui__validation--tax-seller-info': 'Info for buyers is missing',
        'ui__validation--tax-seller-info-error': 'Info for buyers is erroneus',
        'ui__validation--tax-seller-info-error-ctt': 'Info for buyers is erroneus',
        'ui__validation--tax-buyer-info': 'Info for sellers is missing',
        'ui__validation--tax-buyer-info-error': 'Info for sellers is erroneus',
        'ui__validation--tax-buyer-info-error-ctt': 'Info for sellers is erroneus',
        'ui__validation--tax-invalid-idcode': 'Id code for $0 is invalid',
        'ui__validation--tax-office-address': 'Address for office $0 is missing',
        'ui__validation--tax-office-zipcode': 'Zipcode for office $0 is missing',
        'ui__validation--tax-office-city': 'City for office $0 is missing',
        'ui__validation--tax-office-telephone': 'Telephone for office $0 is missing',
        'ui__validation--tax-office-company-name': 'Company name for office $0 is missing',
        'ui__validation--tax-office-company-vatcode': 'Company vat-code for office $0 is missing',
        'ui__validation--deal-split-exactly': 'Total fees mast be 100 %',
        'ui__validation--deal-split-seller_error': 'Add seller. ',
        'ui__validation--no-procurement-results': 'TBD',
        'ui__validation--tax-target-new': 'TBD',
        'ui__error--add-file': 'Adding file was unsuccesful.',
        'ui__error--delete-file': 'Deleting the file was unsuccessful.',
        'ui__error--delete-files': 'Deleting files was unsuccessful.',
        'ui__error--action-failed': 'Action failed.',
        'ui__error--saving-failed': 'Saving failed.',
        'ui__error--connection-error': 'Connection timeout failure.',
        'ui__error--try-again': 'Please try again in a moment.',
        'ui__error--connection-timeout': 'Connection timeout failure. Please try again in a moment.',
        'ui__error--connection-missing': 'Connection failure. Please check your network connection.',
        'ui__error--unknown-saving': 'An error occurred while trying save. Please try again in a moment.',
        'ui__error--proplem-persists': 'If problem persists. Contact our support:',
        'ui__error--email-failed': 'Sending the email failed.',
        'ui__info--email-success': 'Sending the email was succesful.',
        'ui__info--password-update-success': 'Password update was successful',
        'ui__error--fetch-failed': 'Error while fetching information.',
        'ui__units--volume': '',
        'ui__filters--filter': 'Filter',
        'ui__filters--reset': 'Reset filter',
        'ui__filters--reset-all': 'Reset filters',
        'ui__order--sort': 'Order',
        'ui__label--view-type': 'View',
        'ui__label--view-type-list': 'List',
        'ui__label--view-type-images': 'Images',
        'ui__error--no-results': 'No results',
        'ui__search--placeholder': 'Search...',
        'ui__search-by-name--placeholder': 'Search by name...',
        'ui__search-by-name-and-vat--placeholder': 'Search by name or VAT...',
        'ui__user-menu--profile': 'Profile settings',
        'ui__confirmation--data-saved': 'Data saved',
        'ui__confirmation--picture-saved': 'Picture saved',
        'confirm__form--heading': 'Sale status change',
        'confirm__form--touched': 'Discard usaved changes?',
        'confirm__modal--touched': 'Continue and save unsaved data',
        'confirm__modal--admin': 'TBD',
        'confirm__form--assignment-missing-price': 'Paid on deal price is not defined. Do you want to continue?',
        'confirm__assignment--duplicate': 'Do you want to copy current assignment as new?',
        'confirm__file--delete': 'Do you want to delete the file',
        'confirm__file--delete-multiple': 'Do you want to delete following files',
        'confirm__archive--signing-cancel': 'Do you want to cancel sining the file?',
        'contact__general--removed': 'Removed',
        'contact__general--name': 'Name',
        'contact__general--forename': 'Forename',
        'contact__general--surname': 'Surname',
        'contact__general--phone': 'Phone',
        'contact__general--title': 'Title',
        'contact__general--email': 'Email',
        'contact__general--address': 'Address',
        'contact__general--zipcode': 'Zipcode',
        'contact__general--city': 'City',
        'contact__general--post-office': 'Post office',
        'contact__general--hometown': 'Hometown',
        'contact__general--idcode': 'ID code',
        'contact__general--idcode-short': 'ID',
        'contact__general--bank-account': 'Bank account number',
        'contact__general--bank-account-bic': 'BIC',
        'contact__general--description': 'Description',
        'contact__general--person-in-charge': 'Person in charge',
        'contact__general--contact-person': 'Contact person',
        'contact__general--assigner': 'Assigner',
        'contact__general--assigner-placeholder': 'Search for customer or add new...',
        'contact__general--assigners': 'Assigners',
        'contact__general--approver': 'Approver',
        'contact__general--warranter': 'Warranter',
        'contact__general--marketing-permission': 'Marketing permission',
        'contact__general--approve-data-saving': 'Approve storing of personal data',
        'contact__modify--add-new': 'Add new contact',
        'contact__modify--add-customer': 'Add mandator',
        'contact__modify--add-buyer': 'Add buyer',
        'contact__modify--add-seller': 'Add seller',
        'contact__modify--edit': 'Edit contact',
        'contact__type--company': 'Company',
        'contact__type--person': 'Person',
        'contact__type--man': 'Man',
        'contact__type--woman': 'Woman',
        'contact__type--group': 'Group',
        'contact__type--heirs': 'Heir collective',
        'contact__header--customer-card': 'Customer',
        'contact__header--customer-new': 'New customer',
        'company__general--vat-code': 'VAT Code',
        'company__general--hometown': 'Hometown',
        'company__general--housing-registry': 'Electronic housing registry',
        'company__general--manager-company': 'Manager company',
        'company__general--manager-name': 'Manager name',
        'company__general--manager-address': 'Manager address',
        'company__general--manager-email': 'Manager email',
        'company__general--manager-phone': 'Manager phone',
        'company__general--manager-cert-date': 'Manager certificate date',
        'company__general--propertymanagement': 'Property management',
        'company__general--feebase': 'Fee base',
        'company__general--possessions': 'Possessions',
        'company__general--returns-per-year': 'Returns per year',
        'company__general--returns-year': 'Year of returns',
        'company__general--loans': 'Long term loans',
        'company__general--loans-date': 'Loans date',
        'company__general--redemption': 'Redemption clause (apts/others)',
        'company__general--redemption-carslot': 'Redemption clause (carslots)',
        'company__general--restrictions': 'Usage and handover restrictions',
        'company__general--obligations': 'Other obligations',
        'company__general--extra': 'Extra info on company',
        'company__general--site-ownership': 'Site ownership',
        'company__general--site-ownership-own': 'Own',
        'company__general--site-ownership-rent': 'Rented',
        'company__general--site-ownership-optional-rent': 'Rent optional',
        'company__general--site-area': 'Site area',
        'company__general--rental-site-info': 'Rental site description',
        'company__general--landlord': 'Landlord',
        'company__general--rent-last-date': 'Rend valid until',
        'company__general--rent-per-year': 'Rent per year',
        'company__general--built-year': 'Year built',
        'company__general--buildingmaterial': 'Building material',
        'company__general--roof': 'Roofing type',
        'company__general--floors-total': 'Building floors',
        'company__general--warming': 'Warming',
        'company__general--builder': 'Builder',
        'company__general--flats': 'Flats',
        'company__general--flat-area': 'Flat area',
        'company__general--offices': 'Offices',
        'company__general--officearea': 'Office area',
        'company__general--volume': 'Volume',
        'company__general--antenna': 'Antenna',
        'company__general--carslots': 'Car slots',
        'company__general--sauna': 'Common sauna in building',
        'company__general--common-area': 'Other common areas',
        'company__general--ventilation': 'Ventilation',
        'company__general--broadband': 'Broadband',
        'company__general--basic-repairs': 'Basic repairs',
        'company__general--coming-repairs': 'Coming repairs',
        'company__general--new-housing': 'New housing',
        'company__general--construction-phase': 'Construction phase',
        'company__general--city': 'Company city',
        'company__general--site-number': 'Site number',
        'company__general--construction-bank-office': 'Construction time bank',
        'company__general--construction-bank-account': 'Construction time account number',
        'company__general--construction-bank-iban': 'Construction time IBAN',
        'company__general--construction-bank-bic': 'Construction time BIC',
        'company__general--bank': 'Bank',
        'company__general--bank-office': 'Sales price bank',
        'company__general--bank-account': 'Sales price account number',
        'company__general--bank-iban': 'Sales price IBAN',
        'company__general--bank-bic': 'Sales price BIC',
        'company__general--barred-bank-office': 'Barred account bank',
        'company__general--barred-bank-account': 'Barred account number',
        'company__general--barred-bank-iban': 'Barred account IBAN',
        'company__general--barred-bank-bic': 'Barred account BIC',
        'company__general--interest-rate': 'Interest',
        'company__general--breach-of-contract': 'Breach of contract due to late payment',
        'company__general--breach-of-contract-compensation':
            'Seller has right to compensation of losses as per Housing Transactions Act',
        'company__general--breach-of-contract-fees-and-interest': 'Buyer pays fees and 2% of debt free price',
        'company__general--satety-doc-keeper': 'Safety doc keeper',
        'company__general--satety-doc-keeper-address': 'Safety doc keeper address',
        'company__general--satety-doc-keeper-signature': 'Safety doc signer',
        'company__general--construction-phase-deposit': 'Construction phase deposit',
        'company__general--incapability-deposit': 'Incapability deposit',
        'company__general--deposit-place-date': 'Place and date of deposit',
        'company__general--deposit-attachment': 'Has attached proof of possession',
        'company__general--built-date-earliest': 'Built date at the earliest',
        'company__general--built-date-latest': 'Built date at the latest',
        'company__general--built-date-estimated': 'Built date estimation',
        'company__general--building-permit-applied': 'Building permit applied',
        'company__general--building-permit-info': 'Building permit info',
        'company__general--contract-attachemnts': 'Titles of contract attachments',
        'company__general--contract-attachemnts-carslot': 'Titles of contract attachments (car slots)',
        'company__general--contract-attachemnts-office': 'Titles of contract attachments (offices)',
        'company__general--additional-terms': 'Additional terms',
        'company__general--additional-terms-carslot': 'Additional terms (car slots)',
        'company__general--additional-terms-office': 'Additional terms (offices)',
        'dias__general--prerequisites-buyer-bank': 'Buyer bank',
        'dias__general--prerequisites-seller-bank': 'Seller bank',
        'dias__general--prerequisites-bank-has-accepted': 'Accepted',
        'dias__general--prerequisites-bank-is-waiting-for-approval': 'Waiting for an approval',
        'dias__general--prerequisites-bank-approval-waiting-timeout': 'Waiting time has expired',
        'dias__general--dias-process-aborted': 'Unable to start digital real estate sale',
        'dias__general--dias-process-restart': 'Restart the DIAS process',
        'dias__general--dias-process-cancel': 'Cancel the DIAS process',
        'dias__general--dias-process-ongoing': 'DIAS process ongoing',
        'dias__general--signing-step-heading-signing': 'Signing',
        'dias__general--signing-step-signing-pending': 'Signing is pending',
        'dias__general--signing-step-signing-done': 'Signing completed',
        'dias__general--signing-step-signing-error': 'Signing time expired',
        'dias__general--signing-step-waiting-for-payment': 'Waiting for a payment',
        'dias__general--signing-step-seller-bank-payment': 'Seller bank payments',
        'dias__general--signing-step-buyer-bank-payment': 'Buyer bank payments',
        'dias__general--signing-step-seller-bank-sending-share': 'Seller bank sending share to buyer bank',
        'dias__general--signing-step-wait-sending-share': 'Waiting to send share',
        'dias__general--signing-step-share-sent': 'Share sent',
        'dias__general--signing-step-buyer-bank-received-share': 'Buyer bank has received share',
        'dias__general--signing-step-wait-receiving-share': 'Waiting share',
        'dias__general--signing-step-received-share': 'Share received',
        'dias__general--signing-step-payment-done': 'Payment received',
        'dias__general--signing-step-payment-error': 'Payment time expired',
        'dias__general--signing-step-heading-signing-completed': 'Sale completed',
        'dias__general--share-of-sale-fee': 'Share of the sale fee',
        'dias__general--share-of-sales-tax': 'Share of the sale tax',
        'dias__general--includes-document-fees': 'Includes document fees of',
        'dias__general--processing-info-text':
            'Dias process has been started. Wait while the information is processed.',
        'dias__errors--check-seller-fieds': 'Check seller information.',
        'dias__errors--check-dealer-fieds': 'Check dealer information.',
        'dias__errors--moved-to-manual-process': 'DIAS moved to manual processing.',
        'dias__errors--too-many-deed-attachments': 'Choose only one deed from attachments.',
        'dias__errors--no-added-deed-attachment': 'No deed attachment selected.',
        'dias__errors--no-added-sharebook-attachment': 'No share book attachment selected.',
        'dias__errors--not-a-pdf-attachment': 'Choose only pdf files as an attachment.',
        'dias__errors--timeout': 'DIAS-trade status check failed. Try again later.',
        'dias__errors--check-seller-field-fees': 'Check seller fee fields. Field sum is incorrect.',
        'dias__errors--seller-fee-sum-invalid': 'Fee sum is incorrect.',
        'dias__errors--attachment-size-too-big': 'File size maximum is 8 MB. Check the following files:',
        'dias__errors--aborted-for-a-reason': 'TBD',
        'dias__headings--seller-information': 'Seller information',
        'dias__headings--buyer-information': 'Buyer information',
        'dias__headings--start-sale': 'Start sale process',
        'dias__headings--missing-info-seller': 'Seller information is missing.',
        'dias__headings--missing-info-buyer': 'Buyer information is missing.',
        'dias__headings--missing-info-sales-tax': 'Tax announcement information is missing.',
        'dias__headings--invalid-info-sales-tax': 'Tax announcement buyers not corresponding sale deed.',
        'dias__headings--missing-info-deed': 'Deed information is missing.',
        'dias__headings--missing-info-seller-missing-fieds':
            'Seller #$1 information is incomplete, add the following fields:',
        'dias__headings--missing-info-buyer-missing-fieds':
            'Buyer #$1 information is incomplete, add the following fields:',
        'dias__headings--deed-missing-from-archive': 'Deed is missing from archive.',
        'dias__headings--missing-fields': ' Missing fields: ',
        'dias__headings--missing-assignment-information': 'Real estate information insufficient.',
        'dias__headings--missing-deal-fee': 'Real estate deal fee is missing.',
        'dias__headings--missing-iban-or-bic': 'Missing IBAN or BIC information.',
        'dias__headings--bic-error': 'Virheellinen BIC.',
        'dias__headings--add-missing-deal-fee': 'Add deal fee',
        'dias__headings--add-info-seller': 'Add seller information',
        'dias__headings--add-info-buyer': 'Add buyer information',
        'dias__headings--add-info-bank': 'Bank',
        'dias__headings--seller-banks-must-be-same': 'DIAS-trade sellers have to have the same bank office',
        'dias__headings--buyer-banks-must-be-same': 'DIAS-trade buyers have to have the same bank office',
        'dias__headings--add--infosales-tax': 'Add tax announcement information',
        'dias__headings--add--fix-correct-customers': 'Modify buyers information',
        'dias__headings--add--fix-address': 'Fix address',
        'dias__headings--add-info-deed': 'Add deed information',
        'dias__headings--add-info-assignment': 'Add required information',
        'dias__headings--add-deed-to-archive': 'Add deed into archive',
        'dias__headings--add-customer-bank-information': 'Add bank account',
        'dias__headings--add-iban-and-bic': 'Add IBAN and BIC',
        'dias__headings--sale-process-canceled': 'Digital real estate sale has been canceled.',
        'dias__headings--sale-reference-number': 'Reference number',
        'dias__headings--dealer-details': 'Dealer details',
        'dias__headings--dealer-details-email-missing': 'Dealer office email missing',
        'dias__headings--sale-attachments': 'Sale attachments',
        'dias__headings--attachment-observe': 'Obs. attachments are shown to all parties of the sale.',
        'dias__headings--information-correct-agreement': 'TBD',
        'dias__headings--refresh-status-polling': 'Refresh status',
        'dias__headings--third-party-text1': 'TBD',
        'dias__headings--third-party-text2': 'TBD',
        'dias__headings--third-party-text3': 'TBD',
        'dias__headings--event-log': 'Dias Event log',
        'dias__headings--event-log-timestamp': 'Timestamp',
        'dias__headings--event-log-event': 'Event',
        'dias__headings--event-log-no-events': 'No events',
        'dias__headings--event-processing-title': 'DIAS process started',
        'assignment__headings--contract-info': 'Contract',
        'assignment__headings--procurements': 'Procurements',
        'assignment__headings--target-info': 'Target',
        'assignment__headings--housing': 'Housing',
        'assignment__headings--property': 'Property',
        'assignment__headings--building': 'Building',
        'assignment__headings--marketing': 'Marketing',
        'assignment__headings--transaction': 'Transaction',
        'assignment__headings--dias': 'Digital real estate',
        'assignment__headings--dias-prerequisites': 'Prerequisite check for digital real estate sale',
        'assignment__headings--dias-signing-info': 'Remember to do tax announcements after the sale payments are done.',
        'assignment__headings--dias-signing': 'Digital real estate ongoing sale',
        'assignment__headings--dias-attachments': 'Attachments for the sale',
        'assignment__headings--contractforms': 'Forms',
        'assignment__headings--tradebids': 'Tradebids',
        'assignment__headings--languagestab': 'Localization',
        'assignment__headings--archive': 'Archive',
        'assignment__headings--buy-offers': 'Buy offers',
        'assignment__headings--pep-forms': 'PEP-questionnaires',
        'assignment__headings--diary': 'Diary',
        'assignment__headings--flat-info': 'Apartment information',
        'assignment__headings--pricing-fees': 'Pricing and costs',
        'assignment__headings--other-info': 'Other info',
        'assignment__headings--energy-classification': 'Energy classification',
        'assignment__headings--internet': 'Internet',
        'assignment__headings--multimedia-links': 'Media links',
        'assignment__headings--photos': 'Photos',
        'assignment__headings--contract-basics': 'Contract basics',
        'assignment__headings--responsible-persons': 'Responsible persons',
        'assignment__headings--bids': 'Bids',
        'assignment__headings--sales-info': 'Sales info',
        'assignment__headings--sales-tax': 'Sales tax announcement',
        'assignment__headings--sales-tax-sent': 'Sales tax announcement sent',
        'assignment__headings--fee-division': 'Fee division',
        'assignment__headings--select-tax-receiver': 'Select tax receiver',
        'assignment__headings--space-n-storage': 'Space and storage',
        'assignment__headings--levels-n-materials': 'Levels and materials',
        'assignment__headings--dishwashers': 'Dishwashing',
        'assignment__headings--stoves-n-ovens': 'Stoves and ovens',
        'assignment__headings--refridgeration': 'Refridgeration',
        'assignment__headings--sauna': 'Sauna',
        'assignment__headings--showers': 'Showers',
        'assignment__headings--baths': 'Baths',
        'assignment__headings--machinery': 'Machinery and HVAC',
        'assignment__headings--others': 'Others',
        'assignment__headings--set-position': 'Set position',
        'assignment__headings--floor-material': 'Floor material',
        'assignment__label--internet-target-number': 'Target number',
        'assignment__label--assignment-number': 'Assignment number',
        'assignment__headings--extend-contract-terms': 'Extended contract terms',
        'assignment__label--number': 'Number',
        'assignment__label--name': 'Name',
        'assignment__label--address': 'Street address',
        'assignment__label--district': 'District',
        'assignment__label--country': 'Country',
        'assignment__label--region': 'Province',
        'assignment__label--coordinates': 'Coordinates',
        'assignment__label--room-count': 'Room count',
        'assignment__label--area-living': 'Living area',
        'assignment__label--area-additional': 'Additional area',
        'assignment__label--area-total': 'Total area',
        'assignment__label--area-extra-info': 'Area extra info',
        'assignment__label--target-features': 'Target features',
        'assignment__label--balcony-description': 'Balcony details',
        'assignment__label--terrace-description': 'Terrace details',
        'assignment__label--flat-window': 'Window direction',
        'assignment__label--other-details': 'Other details',
        'assignment__label--area-information': 'Area extra information',
        'assignment__label--balcony': 'Balcony',
        'assignment__label--terrace': 'Terrace',
        'assignment__label--sauna': 'Sauna',
        'assignment__label--lift': 'Elevator',
        'assignment__label--asbestos-survey': 'Asbestos survey',
        'assignment__label--asbestos-description': 'Asbestos survey results',
        'assignment__label--beach-type': 'Beach',
        'assignment__label--waterfront-type': 'Waterfront type',
        'assignment__label--driving-guide': 'Driving guide',
        'assignment__label--share-nos': 'Flat share numbers',
        'assignment__label--share-nos-other': 'Other shares',
        'assignment__label--share-serial': 'Share serial',
        'assignment__label--share-book': 'Share book',
        'assignment__label--share-count': 'Flat share count',
        'assignment__label--share-registry': 'Share location',
        'assignment__label--flat-storey': 'Flat storey',
        'assignment__label--flat-number': 'Flat number',
        'assignment__label--flat-condition-classification': 'Condition classification',
        'assignment__label--flat-condition': 'Condition',
        'assignment__label--flat-kitchen-equipment': 'Kitchen equipment',
        'assignment__label--flat-kitchen': 'Kitchen description',
        'assignment__label--flat-wc': 'WC',
        'assignment__label--livingroom': 'Living room',
        'assignment__label--bedrooms': 'Bedrooms',
        'assignment__label--flat-bathrooms': 'Bathrooms',
        'assignment__label--flat-utility-room': 'Utility room',
        'assignment__label--flat-extra': 'Extra information',
        'assignment__label--flat-extra_brochure': 'Extra information (brochure only)',
        'assignment__label--foundation_type': 'Foundation type',
        'assignment__label--trade-bid-assignment': 'Trade bid assignment',
        'assignment__label--trade-bid-target-debtprice': 'Highest bid',
        'assignment__label--trade-bid-latest': 'Target debt price',
        'assignment__label--trade-bid-no-bids': 'No active trade bids',
        'assignment__label--source-channel': 'Source channel',
        'assignment__label--contract-created': 'Contract created',
        'assignment__label--salesprice': 'Salesprice',
        'assignment__label--salesprice-start': 'Starting sales price',
        'assignment__label--debtprice-goal': 'Debt target price',
        'assignment__label--targetprice-mortgage-debt': 'Mortgage debt',
        'assignment__label--targetprice-mortgage-debt-free': 'Mortgage debt (free?)',
        'assignment__label--targetprice-mortgage-debt-keeping': 'Mortgage bond retainment',
        'assignment__label--targetprice-stress-proof-date': 'Encumbrance certificate date',
        'assignment__label--targetprice-property-tax': 'Property tax',
        'assignment__label--targetprice-water-total': 'Waterfee',
        'assignment__label--targetprice-cleaning': 'Cleaning costs',
        'assignment__label--targetprice-warming': 'Heating costs',
        'assignment__label--targetprices-total': 'Total costs',
        'assignment__label--targetprice-oil-use': 'Oil usage',
        'assignment__label--targetprice-electric-use': 'Electricity heating',
        'assignment__label--targetprice-electric-consumption': 'Electricity usage',
        'assignment__label--debtpart': 'Debt part',
        'assignment__label--debtprice-bid': 'Debt starting price',
        'assignment__label--debtprice': 'Debt price',
        'assignment__label--debtpart-date': 'Debt part reddemable',
        'assignment__label--debtprice-final': 'Final debt price',
        'assignment__label--targetprice-loanpayable': 'Debt is ',
        'assignment__label--targetprice-care': 'Care cost',
        'assignment__label--targetprice-care-sq': 'Care cost by square',
        'assignment__label--targetprice-financing': 'Financing cost',
        'assignment__label--targetprice-repair': 'Repair cost',
        'assignment__label--targetprice-financing-sq': 'Financing cost by square',
        'assignment__label--targetprice-repair-sq': 'Repair cost by square',
        'assignment__label--housingtotalfee': 'Total cost',
        'assignment__label--housingtotalfee-sq': 'Total cost by square',
        'assignment__label--targetprice-rent-per-month': 'Rent per month',
        'assignment__label--targetprice-other-payments': 'Other payments',
        'assignment__label--targetprice-other-buyers-payments': 'Other buyers payments',
        'assignment__label--targetprice-water': 'Water fee (per person)',
        'assignment__label--targetprice-waterspec': 'Water fee extra info',
        'assignment__label--target-freedate-type': 'Release date',
        'assignment__label--target-freedate': 'Date',
        'assignment__label--target-freedate-text': 'Release info',
        'assignment__label--target-lodger': 'Lodger',
        'assignment__label--target-lodger-phone': 'Lodger phone',
        'assignment__label--target-lodgerlivedsince': 'Lodger lived since',
        'assignment__label--target-revoking': 'Revoking',
        'assignment__label--target-revoking-date': 'Revoking date',
        'assignment__label--target-revoker': 'Revoker',
        'assignment__label--target-holding-type': 'Holding type',
        'assignment__label--targetprice-payment-method': 'Payment method',
        'assignment__label--target-burdens': 'Burdens/rights',
        'assignment__label--target-deed-restrictions': 'Deed restrictions',
        'assignment__label--certified-owner': 'Certified owner',
        'assignment__label--acquisition-type-date': 'Acquisition type and date',
        'assignment__label--target-modifications': 'Modifications',
        'assignment__label--target-extra': 'Extra information',
        'assignment__label--target-services': 'Area services',
        'assignment__label--target-schools': 'Schools and kindergartens',
        'assignment__label--target-transport': 'Transportation',
        'assignment__label--property-planning': 'Property planning',
        'assignment__label--property-infosource': 'Property info source',
        'assignment__label--planning-desc': 'Planning description',
        'assignment__label--target-class': 'Property class',
        'assignment__label--costlocation': 'Cost center',
        'assignment__label--target-type': 'Target type',
        'assignment__label--target-details': 'Target details',
        'assignment__label--energy-class': 'Energy class',
        'assignment__label--energy-certificate': 'Energy certificate',
        'assignment__label--energy-certificate-desc': 'Energy certificate description',
        'assignment__label--data-visibility': 'Visibility',
        'assignment__label--data-visibility-other': 'Other publicing services',
        'assignment__label--oikotie': 'Oikotie',
        'assignment__label--oikotie-release': 'TBD',
        'assignment__label--oikotie-no-procurements': 'TBD',
        'assignment__label--oikotie-order-text': 'TBD',
        'assignment__label--oikotie-order-link-text': 'TBD',
        'assignment__label--oikotie-info-text': 'TBD',
        'assignment__label--oikotie-info-text-marketing': 'TBD',
        'assignment__label--oikotie-info-text-campaign': 'TBD',
        'assignment__label--oikotie-step-text-campaign-1': 'TBD',
        'assignment__label--oikotie-step-text-campaign-2': 'TBD',
        'assignment__label--oikotie-price-text-marketing': 'TBD',
        'assignment__label--oikotie-price-text-campaign': 'TBD',
        'assignment__label--oikotie-pricesuffix-text-marketing': 'TBD',
        'assignment__label--oikotie-pricesuffix-text-campaign': 'TBD',
        'assignment__label--oikotie-observation-text-campaign': 'TBD',
        'assignment__label--oikotie-view-ad': 'Preview ad',
        'assignment__label--oikotie-add-visibility': 'TBD',
        'assignment__label--oikotie-add-visibility-order': 'TBD',
        'assignment__label--oikotie-add-visibility-valid-1': 'TBD',
        'assignment__label--oikotie-add-visibility-valid-2': 'TBD',
        'assignment__label--oikotie-buy-campaign': 'TBD',
        'assignment__label--oikotie-campaign-start-date': 'TBD',
        'assignment__label--oikotie-campaign-start-time': 'TBD',
        'assignment__label--oikotie-campaign-heading': 'TBD',
        'assignment__label--oikotie-campaign-link-url': 'TBD',
        'assignment__label--oikotie-campaign-link-text': 'TBD',
        'assignment__label--oikotie-campaign-link-color': 'TBD',
        'assignment__label--oikotie-campaign-picture-title': 'TBD',
        'assignment__label--oikotie-campaign-picture-info': 'TBD',
        'assignment__label--oikotie-campaign-picture-info2': 'TBD',
        'assignment__label--oikotie-campaign-picture-button': 'TBD',
        'assignment__label--oikotie-campaign-buy': 'TBD',
        'assignment__label--oikotie-campaign-remove': 'TBD',
        'assignment__label--oikotie-order': 'TBD',
        'assignment__label--etuovi': 'Etuovi',
        'assignment__label--kauppalehti': 'Kauppalehti',
        'assignment__label--toimitilatfi': 'Toimitilat',
        'assignment__label--sales-brochure': 'Sales brochure',
        'assignment__label--window-display': 'Window display',
        'assignment__label--window-display-marketing': 'Window screen display',
        'assignment__label--website': 'Website',
        'assignment__label--heading': 'Heading',
        'assignment__label--internet-text': 'Description',
        'assignment__label--brochure-text': 'Brochure description',
        'assignment__label--windowcard-text': 'Windowcard description',
        'assignment__label--virtual-presentation': 'Virtual presentation link',
        'assignment__label--videoclip': 'Video clip link',
        'assignment__label--marketing-link': 'Marketing link',
        'assignment__label--construction-stage': 'Const.stage',
        'assignment__label--flat-count': 'Flats',
        'assignment__label--office': 'Office',
        'assignment__label--description': 'Description',
        'assignment__label--description-abr': 'Desc',
        'assignment__label--customer': 'Customer',
        'assignment__label--dealer': 'Dealer',
        'assignment__label--person': 'Supplier #1',
        'assignment__label--person2': 'Suplier #2',
        'assignment__label--person3': 'Handler',
        'assignment__label--person4': 'Salesman',
        'assignment__label--person5': 'Salesman #2',
        'assignment__label--person-salesmen': 'Salesmen',
        'assignment__label--split1': 'Supplier #1 share',
        'assignment__label--split2': 'Supplier #2 share',
        'assignment__label--split3': 'Handler share',
        'assignment__label--split4': 'Salesman share',
        'assignment__label--validity': 'Validity',
        'assignment__label--contract-date': 'Contract date',
        'assignment__label--commission': 'Commission',
        'assignment__label--commission-total': 'Commission in total',
        'assignment__label--housing': 'Housing company',
        'assignment__label--target-new': 'New home',
        'assignment__label--target-holiday': 'Holiday home',
        'assignment__label--validity-start': 'Validity start',
        'assignment__label--validity-end': 'Validity end',
        'assignment__label--validity-continues': 'Validity continues 1mo at a time',
        'assignment__label--internal-memo': 'Internal memo',
        'assignment__label--buyer-customer': 'Buyer',
        'assignment__label--buyer-customer-2': 'Buyer #2',
        'assignment__label--buyer-customers': 'Buyers',
        'assignment__label--diary-salesprice': 'Final salesprice',
        'assignment__label--deal-downpayment-amount': 'Downpayment',
        'assignment__label--deal-downpayment-date': 'Downpayment date',
        'assignment__label--deal-on-deal-amount': 'Paid on deal',
        'assignment__label--deal-date': 'Deal date',
        'assignment__label--deal-final-payment': 'Final payment',
        'assignment__label--deal-moving-date': 'Moving date',
        'assignment__label--deal-last-payment': 'Last payment terms',
        'assignment__label--deal-possession-transfers': 'Possession transfers',
        'assignment__label--deal-ownership-transfer-date': 'Ownership transfer date',
        'assignment__label--deed-edit': 'Edit contract',
        'assignment__label--deed-create': 'Create contract',
        'assignment__label--deed-show': 'Show contract',
        'assignment__label--deed-forward': 'Finalize deal',
        'assignment__label--deed-to-archive': 'to archive',
        'assignment__label--deed-in-archive-t1': 'In case deed has not yet been ',
        'assignment__label--deed-in-archive': 'archived',
        'assignment__label--deed-in-archive-t2': ' then open it from below',
        'assignment__label--deed-with-old': 'Target has been marked as sold with using old PDX deed tool.',
        'assignment__label--deed-with-none': 'Target has been marked as sold without creating deed via PDX.',
        'assignment__label--deed-with-none-text': 'Check for or add a deed ',
        'assignment__label--deed-with-new-text': 'Deed is archived ',
        'assignment__label--deal-type': 'Deal type',
        'assignment__label--cash-deal': 'Cash deal',
        'assignment__label--installment-payment': 'Installment payment',
        'assignment__label--ownership-transfers': 'Ownership transfers',
        'assignment__label--deal-fee-substract': 'Fee subastraction',
        'assignment__label--deal-fee-substract-reason': 'Fee subastraction reason',
        'assignment__label--flat-total-storeys': 'Total storyes',
        'assignment__label--property-usagetype': 'Usage type',
        'assignment__label--property-type': 'Property type',
        'assignment__label--property-city-type': 'Munipalicity or city',
        'assignment__label--unseparated-parcel': 'Unseparated piece of land',
        'assignment__label--property-identifier': 'Property identifier',
        'assignment__label--property-registration-date': 'Property registration date',
        'assignment__label--property-city': 'Munipalicity/city',
        'assignment__label--property-district': 'Village/district',
        'assignment__label--property-name': 'Property name',
        'assignment__label--property-reg-no': 'Registration number',
        'assignment__label--property-block': 'Block',
        'assignment__label--property-site-number': 'Site number',
        'assignment__label--property-lot-number': 'Lot number',
        'assignment__label--property-rent-ends': 'Rent ends',
        'assignment__label--property-rent': 'Rent',
        'assignment__label--property-tenancy-handover': 'Rent handover',
        'assignment__label--property-landscape': 'Landscape',
        'assignment__label--property-soil': 'Soil',
        'assignment__label--property-limitation': 'Area limitations',
        'assignment__label--property-building-right': 'Building right',
        'assignment__label--property-public-utility': 'Public utility',
        'assignment__label--property-water': 'Water for consumption',
        'assignment__label--property-sewage': 'Sewage',
        'assignment__label--property-access': 'Plot access',
        'assignment__label--property-extra': 'Extra information',
        'assignment__label--tax-bailor': 'Tax bailor',
        'assignment__label--tax-receiver': 'Tax receiver',
        'assignment__label--tax-idcode': 'ID code/VAT',
        'assignment__label--tax-share': 'Share',
        'assignment__label--tax-share-error': 'Input error',
        'assignment__label--tax-first-time-buyer': 'First time buyer',
        'assignment__label--bank-transfer': 'Bank transfer',
        'assignment__label--tax-is-moving': 'Is moving',
        'assignment__label--tax-capital-transfer-relative': 'Transfer to relative',
        'assignment__label--tax-capital-transfer-dealer-trade': 'Dealer trade',
        'assignment__label--tax-capital-transfer-company-partner':
            'Was the acquirer a shareholder or a partner of conveyor company before the transaction or their parent, spouse or child',
        'assignment__label--tax-capital-transfer-seller-company-partner':
            'Is the seller a shareholder or a partner or acquirer shareholders or partners parent, spouse or child?',
        'assignment__label--tax-capital-transfer-internal-company-partner':
            'Is this an internal assignment of a combine or a company and it´s shareholder or partner?',
        'assignment__label--capital-transfer-tax-percent': 'Transfer tax percent',
        'assignment__label--capital-transfer-tax': 'Transfer tax',
        'assignment__label--capital-transfer-debtpart-tax': 'Transfer tax from debt part',
        'assignment__label--capital-transfer-payday': 'Due date',
        'assignment__label--capital-transfer-regular-flat-date': 'Regular resident from',
        'assignment__label--capital-transfer-flat-area': 'Part used as an apartment (m²)',
        'assignment__label--capital-transfer-flat-percent': 'Part used as an apartment (%)',
        'assignment__label--transfer-tax-form-date': 'Announcement date',
        'assignment__label--tax-payable': 'Tax amount',
        'assignment__label--tax-paid-comfirmation': 'Buyers have paid capital transfer taxes',
        'assignment__label--status-change-missing-fields': 'Fill the following fields',
        'assignment__label--price-not-be-updated': 'Begin date is in the future, price will not be updated.',
        'assignment__label--remember-to-update-price':
            'Remember to change the selling price at $DATE, when the new price will apply.',
        'assignment__commission--fee-type': 'Fee type',
        'assignment__commission--fee-type-percent': 'Percent',
        'assignment__commission--fee-type-fixed': 'Fixed minumum',
        'assignment__commission--fee-percent': 'Fee %',
        'assignment__commission--fee-incl-tax': 'Incl. tax ',
        'assignment__commission--fee-from-debt': 'Fee from debt price',
        'assignment__commission--min-fixed-fee': 'Min fixed fee',
        'assignment__commission--fee-substract': 'Fee substraction €',
        'assignment__commission--fee-substract-reason': 'Fee substraction reason',
        'assignment__commission--fee-with-tax': 'Fee incl. tax €',
        'assignment__commission--fee-with-no-tax': 'Fee excl. tax €',
        'assignment__commission--fee-payment-date': 'Fee payment date',
        'assignment__commission--fee-details': 'Fee details',
        'assignment__search-units': 'Listings',
        'assignment__status--draft': 'Draft',
        'assignment__status--premarketing': 'Premark.',
        'assignment__status--on-sale': 'On sale',
        'assignment__status--free': 'Free',
        'assignment__status--sold': 'Sold',
        'assignment__status--max-amount-reached': 'Maximum amount reached.',
        'assignment__status--in-progress': 'In progress',
        'assignment__status--found': 'Found',
        'assignment__target-type--flats': 'Flats',
        'assignment__target-type--offices': 'Offices',
        'assignment__target-type--storages': 'Storages',
        'assignment__target-type--carslots': 'Car slots',
        'assignment__target-type--block': 'Apartment build.',
        'assignment__target-type--rowhouse': 'Rowhouse',
        'assignment__target-type--semi': 'Semi-detached',
        'assignment__target-type--carslot': 'Car slot',
        'assignment__asset-label': 'Omaisuuslaji VSV-ilmoitukseen',
        'assignment__asset-type-apartment': 'Apartment',
        'assignment__asset-type-garage': 'Garage',
        'assignment__asset-type-time-share': 'Time share',
        'assignment__flat_share_add-button': 'Add extra share',
        'assignment__flat_share_info-text-debt': 'PDX does not support situation where extra shares include debt.',
        'assignment__flat_share_info-text':
            'First share numbers (field above) is by default non-taxable when buying first apartment.',
        'assignment__flat_share_no-tax-text': 'is also non-taxable share numbers when buying first apartment',
        'assignment__action--fill-persons': 'Prefill persons',
        'assignment__action--count-fee': 'Count fee',
        'assignment__action--add-new-housing': 'Add new housing company',
        'assignment__action--cancel-new-housing': 'Cancel new housing',
        'assignment__action--send-capital-transfer-form': 'Send capital transfer form',
        'assignment__action--send-capital-transfer-form-alternate': 'Send capital transfer form',
        'assignment__action--do-capital-transfer-payment': 'Print capital transfer payments',
        'assignment__action--create-new': 'Add new assignment',
        'assignment__action--finalize-sale': 'Confirm sale',
        'assignment__action--invoice-fee': 'Create fee invoice',
        'assignment__action--view-ad': 'View ad',
        'assignment__action--status-incomplete-sold': 'Cancel deal',
        'assignment__action--status-incomplete-terminated': 'Restore assignment',
        'assignment__action--status-sale': 'Mark for sale',
        'assignment__action--status-in-search': 'Mark for search',
        'assignment__action--status-reserved': 'Mark reserved',
        'assignment__action--status-target-found': 'Target found',
        'assignment__action--status-sale-reserved': 'Return for sale',
        'assignment__action--status-return-to-searching': 'Return for search',
        'assignment__action--status-terminated': 'Terminate assignment',
        'assignment__action--extend-contract': 'Extend contract',
        'assignment__action--duplicate': 'Copy as new',
        'assignment__error--fee-splits-mismatch-alert': 'Fee split sum does not match 100%.',
        'housing__heading--contract-info': 'Contract info',
        'housing__label--flat-reservations': 'Reserved / Sold / Total',
        'housing__search-unit': 'Companies',
        'assignment__error--fee-data-missing': 'Fill in fee information',
        'assignment__error--seller-missing': 'Add seller',
        'assignment__error--illegal-deal-participant': 'Mandator cannot be also a seller or a buyer.',
        'assignment__error--illegal-tax-participant': 'TBD',
        'assignment__error--malformed-fraction-number': 'Malformed fraction number given.',
        'brochure__heading--settings': 'Brochure settings',
        'brochure__heading--create': 'Create a brochure',
        'brochure__label--printer': 'Person',
        'brochure__label--template': 'Brochure template',
        'brochure__label--compressible': 'Compressed layout',
        'brochure__text--print': 'Print',
        'brochure__text--page': 'Page',
        'brochure__text--info': 'Information',
        'brochure__text--infocon': 'Information (continued)',
        'brochure__text--floorplan': 'Floorplan',
        'brochure__text--footer': 'Footer',
        'brochure__text--logo': 'Logo',
        'brochure__text--main-image': 'Main image',
        'brochure__text--basic-info': 'Basic information',
        'brochure__text--description': 'Description',
        'brochure__text--target-image': 'Image',
        'brochure__select-option--empty-cover': 'empty cover letter',
        'brochure__select-option--photo': 'photo',
        'displaybrochure__label--demonstrator': 'Demonstrator',
        'displaybrochure__label--nodemonstrator': 'No demonstrator',
        'displaybrochure__heading--config': 'Displaycard config',
        'displaybrochure__heading--create': 'Create a display brochure',
        'pricelist__heading--settings': 'Pricelist config',
        'pricelist__heading--fields': 'Pricelist fields',
        'pricelist__heading--extra': 'Extra settings',
        'pricelist__heading--date': 'Pricelist date',
        'pricelist__settings--show-salesprices': 'Show sold salesprices',
        'pricelist__settings--show-salesprices-reserved': 'Show reserved salesprices',
        'pricelist__settings--hide-sold': 'Hide sold',
        'pricelist__settings--show-exact-status': 'Show exact status',
        'widgets__title--yield-multi': 'Outcome 6mo / € (vat 0%)',
        'widgets__title--yield-volume': 'Outcome 6mo / volume',
        'widgets__title--yield-current': 'Outcome now (vat 0%)',
        'widgets__title--news': 'Announcements',
        'widgets__title--leads': 'Leads',
        'widgets__title--assignments': 'Assignments',
        'widgets__title--customer-actions': 'New customer',
        'widget__lead--actions-interest': 'Interest',
        'widget__lead--actions-contact': 'Contact info',
        'widget__lead--actions-transfer': 'Transfer to user',
        'widget__lead--label-transfer': 'Transfer',
        'widget__lead--label-transfer-done': 'Transfered',
        'widget__yield--result-label': 'Percent from goal',
        'widget__yield--goal': 'Goal',
        'widget__yield--result': 'Outcome',
        'widget__yield--reserve': 'Reservations',
        'person__label--show-deactivated': 'Show deactivated',
        'person__label--ssn-error': 'SSN is already registered to customer database',
        'person__action--create-new': 'Create new person',
        'archive__heading--add-file': 'Add file',
        'archive__heading--digital-signing': 'Digital signage',
        'archive__heading--signers': 'Signers',
        'archive__heading--send-files-email': 'Send files by email',
        'archive__label--enable-inhouse': 'Use agents representative as a signer',
        'archive__label--signing-inhouse-person': 'Signing representative',
        'archive__label--add-signer': 'Add signer',
        'archive__label--signed-file': 'File to sign',
        'archive__label--inviter': 'Inviter',
        'archive__label--no-files': 'No files',
        'archive__label--next-page': 'Next Page',
        'archive__label--previous-page': 'Previous Page',
        'archive__label--rows-per-page': 'Rows Per Page',
        'archive__label--files-to-send': 'Files to be sent',
        'archive__label--sender-name': 'Sender name',
        'archive__label--sender-email': 'Sender email',
        'archive__label--receiver-email': 'Receiver email',
        'archive__label--email-cc': 'Send to sender email as CC',
        'archive__label--message': 'Message',
        'archive__action--sign': 'Sign',
        'archive__action--send-to-signing': 'Send to signing',
        'archive__action--cancel-signing': 'Cancel signing',
        'archive__action--signing-waiting-sending': 'waiting for sending',
        'archive__action--signing-waiting-signing': 'waiting for signing',
        'archive__action--signing-signed': 'signed',
        'archive__action--signing-canceled': 'canceled',
        'archive__action--given-information': 'Given password and address',
        'tooltip__assignment--site-area': 'TOOLTIP TBD',
        'tooltip__assignment--asbestos-survey': 'TOOLTIP TBD',
        'tooltip__assignment--trade-bid-process': 'TOOLTIP TBD',
        'tooltip__assignment--condition-classification': 'TOOLTIP TBD',
        'tooltip__assignment--energy-certificate-desc': 'TOOLTIP TBD',
        'tooltip__assignment--save-before-transfer': 'Please save the document to access bank trasfer details',
        'tooltip__assignment--sales-tax': 'TOOLTIP TBD',
        'tooltip__person--image': 'TOOLTIP TBD',
        'assignment__placeholder--tax-share': 'TBD',
        'assignment__dias_introduction-p1': 'TBD',
        'assignment__dias_introduction-p2': 'TBD',
        'assignment__dias_introduction-p3': 'TBD',
        assignment__dias_link_text: 'TBD',
        'person__heading--change-password': 'Change password',
        'person__title--person-information': 'Personnel information',
        'person__title--person-basic-information': 'Basic information',
        'person__title--person-extra-information': 'Additional information',
        'person__title--goals': 'Personal goals',
        'person__title--rights': 'Account information',
        'person__label--username': 'Username',
        'person__label--password': 'Password',
        'person__label--premises-dealer': 'Premises dealer',
        'person__label--premises-dealer-text':
            'Premises dealer primarily deals business premises. This is taken into account within this service.',
        'person__label--profile-picture': 'Profile picture',
        'person__label--profile-picture-text': 'Picture for marketing-portals.',
        'person__label--upload-picture': 'Upload picture',
        'person__label--goals-text-p1':
            'This section describes sales goals. Goals can be marked down as euros or number of sales for coming months.',
        'person__label--goals-text-p2':
            'Goal dates can be set per month or per year and they can be modified during the year.',
        'person__label--intro-text': 'Text introduction',
        'person__label--intro-video': 'Video introduction',
        'person__label--visible': 'Show on webpages',
        'person__label--on-vacation': 'On vacation',
        'person__label--receive-leads': 'Receive leads',
        'person__label--billed': 'Account is billed',
        'person__label--domains': 'Domains',
        'person__label--deactivated': 'Deactivate account',
        'person__label--total-goal': 'Total goal',
        'person__action--change-password': 'Change password',
        'person__action--old-password': 'Current password',
        'person__action--new-password': 'New password',
        'person__action--new-password-2': 'Password confirmation',
        'person__error--password-mismatch': 'Passwords do not match',
        'person__error--password-old': 'Current password is not valid',
        'person__error--password-new-length-min': 'New password must be at least $0 chars',
        'person__error--password-new-length-max': 'New password must be $0 chars at the most',
        'assignment__text--transfer-tax-sent-not-current': 'TBD',
        'assignment__text--transfer-tax-sent': 'TBD',
        'infobox__lablel--brochure-archive': 'TBD',
        'infobox__lablel--pricelist-archive': 'TBD',
        'infobox__lablel--windowcard-archive': 'TBD',
        login__label: 'Login dialog',
        'pep__label--top-text': 'TBD',
        'login__label--title': 'Login dialog',
        'login__label--login-button': 'Login',
        'login__label--missing-fields': 'Fill username and password fields',
        'login__label--password-length':
            'Your password is too short. Please use the "Did you forget your password?" button to change it and follow the instructions sent to your email.',
        'login__label--login-error': 'Login failed',
        'login__label--did-you-forgot-password': 'Did you forget your password?',
        'login__label--email-error': 'Email sending failed',
        'login__label--forgot-password-email-sent': 'Password changing email sent',
        'login__label--hash-error': 'Error',
        'login__label--new-password-sent': 'New password sent via email!',
        'login__label--login-to-pdx': 'Login to PDX',
        'login__label--sent': 'Sent',
        'login__label--logout-error': 'Logout failed. Refresh page and try again.',
    },
}
