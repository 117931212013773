import React from 'react'
import styled, { ThemeProvider } from 'styled-components'
import trim from 'lodash/trim'
import MainNavigation from './components/navigation/MainNavigation'
import { Fonts } from './styles/StyleConf'
import Config from './config/Config'
import {
    fetchRest,
    validateInstallation,
    installationFromUrl,
    getPasswordHash,
    pdxLegacyRedirect,
} from './utils/APIUtils'
import { SettingsContext } from './context/SettingsContext'
import { ToastContainer, toast, Slide } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.min.css'
import Error from './components/Error'
import Login from './components/login/Login'
import PasswordReset from './components/login/PasswordReset'
import PlainWrapper from './components/layout/PlainWrapper'
import Loader from './components/Loader'

/**
 * @typedef {Object} PDXSettings
 * @property {string} name
 */

/**
 * @typedef {Object} AppSettings
 * @property {PDXSettings} pdx
 */

/**
 * @class App
 */
class App extends React.Component {
    constructor(props) {
        super(props)
        const hash = getPasswordHash()
        this.state = {
            username: null,
            settings: null,
            error: null,
            logging: false,
            hashing: hash ? true : false,
            pdx: installationFromUrl(),
            isLogout: false,
        }

        this.fetchSettings = this.fetchSettings.bind(this)
        this.logonReady = this.logonReady.bind(this)
    }

    componentDidMount() {
        const { settings, logging, hashing } = this.state

        if (!settings && !logging && !hashing) {
            this.fetchSettings()
        }
    }

    fetchSettings() {
        const settingsData = fetchRest({ path: 'settings', noErrorHandling: true, logoutOnFail: false }),
            userData = fetchRest({ path: 'account/info', noErrorHandling: true, logoutOnFail: false }),
            location = trim(window.location.href, '/'),
            paramCount = location.split('/').length - 3

        Promise.all([settingsData, userData])
            .then(res => {
                const [settings, user] = res

                if (!settings || !settings.data) {
                    this.setState({ error: 'timeout', logging: true })
                    return
                }

                if (settings && settings.data && settings.data.pdx && !validateInstallation(settings.data.pdx)) {
                    this.setState({
                        logging: true,
                        isLogout: true,
                    })
                } else if (
                    settings &&
                    settings.data &&
                    settings.data.pdx &&
                    settings.data.pdx.construction === '1' &&
                    paramCount === 1
                ) {
                    pdxLegacyRedirect() // REDIRECTING FOR CONSTRUCTION PDX
                } else if (settings && settings.data && user && user.data) {
                    this.setState({
                        settings: { ...settings.data, user: user.data, error: null },
                        logging: false,
                    })
                }
            })
            .catch(err => {
                console.error(err)
            })
    }

    logonReady() {
        window.location.href = window.location.href.replace('/login', '')
    }

    render() {
        const { logging, pdx, settings, isLogout } = this.state
        const hash = getPasswordHash()

        if (hash) {
            return (
                <PlainWrapper>
                    <PasswordReset hash={hash} />
                </PlainWrapper>
            )
        } else if (logging) {
            return (
                <PlainWrapper>
                    <Login pdx={pdx} logonReady={this.logonReady} isLogout={isLogout} />
                </PlainWrapper>
            )
        }

        return settings ? (
            <AppWrapper className="App">
                <FinalNotice>
                    {' '}
                    <div>
                        Tiedote: PDX+ -kiinteistönvälitysjärjestelmä sulkeutuu lopullisesti 30.11.2023. Laajempi tiedote
                        lähetetty sähköpostitse. Tiedote luettavissa myös{' '}
                        <a href="https://pdx.fi/tiedote-2023-08.pdf">täällä</a>. Lisätietoja{' '}
                        <a href="mailto:tuki@pdx.fi">tuki@pdx.fi</a>
                    </div>
                </FinalNotice>
                <SettingsContext.Provider value={this.state}>
                    <ThemeProvider theme={theme}>
                        <MainNavigation />
                    </ThemeProvider>
                </SettingsContext.Provider>
                <ModalRoot />
                <TooltipRoot />
                <ToastContainer
                    position={toast.POSITION.TOP_CENTER}
                    autoClose={false}
                    transition={Slide}
                    hideProgressBar={true}
                    closeButton={false}
                />
            </AppWrapper>
        ) : (
            this.state.error && (
                <ThemeProvider theme={theme}>
                    <Error type={this.state.error} />
                </ThemeProvider>
            )
        )
    }
}

const AppWrapper = styled.div`
    & * {
        box-sizing: border-box;
    }

    font-family: ${Fonts.brandFont};
    -webkit-font-smoothing: antialiased;
    overflow-x: hidden;
    min-height: 300px;
`

const FinalNotice = styled.div`
    width: 100%;
    height: 25px;
    background: #faa;
    text-align: center;
`

const ModalRoot = styled.div.attrs({ id: Config.ui.modalId })``
const TooltipRoot = styled.div.attrs({ id: Config.ui.tooltipId })``

const bp = Config.breakpoints
const theme = {
    breakpoints: [`${bp.mobile}px`, `${bp.tablet}px`, `${bp.desktop}px`],
}

export default App
