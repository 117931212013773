import React from 'react'
import PropTypes from 'prop-types'
import { withLocale } from '../../context/l10nContext'
import styled from 'styled-components'
import { Formik } from 'formik'
import { TranslateString as t } from '../../utils/DictionaryUtils'
import Button from '../input/Button'
import EditableField from '../input/EditableField'
import {
    sendRest,
    sendRestLogin,
    sendRestLogout,
    installationFromUrl,
    getLoginPath,
    checkCredentials,
    getInstallationPath,
} from '../../utils/APIUtils'
import ForgotPassword from './ForgotPassword'
import { toastConnectionError, toastErrorWithClose, toastInfo } from '../ui/Toast'
import { Slide, toast, ToastContainer } from 'react-toastify'

class Login extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isError: false,
            isSubmitting: false,
            forgetEmail: false,
            pdx: this.props.pdx ? this.props.pdx : installationFromUrl(),
        }

        this.toggleForget = this.toggleForget.bind(this)
        this.setEmailReady = this.setEmailReady.bind(this)

        if (this.props.isLogout) {
            sendRestLogout({
                path: 'account/logout',
                method: 'POST',
                noErrorHandling: true,
            })
                .then(() => {
                    console.log('logout')
                    this.setState({ isLogout: false, isError: false }, () => (window.location.href = getLoginPath()))
                    return
                })
                .catch(() => {
                    console.log('undefined logout error')
                    toast.dismiss()
                    toastErrorWithClose(t(this.props.locale, 'login__label--logout-error'))
                    return
                })
        } else {
            checkCredentials()
                .then(res => {
                    if (String(res) === 'Authorized') window.location.href = getInstallationPath()
                })
                .catch(err => console.error(err))
        }
    }

    setError(error) {
        if (error !== undefined) {
            this.setState({ isError: error })
        }
    }

    setSubmitting(submitting) {
        if (submitting !== undefined) {
            this.setState({ isSubmitting: submitting })
        }
    }

    setEmailReady() {
        this.toggleForget(true)
    }

    toggleForget(hide = false) {
        let forgetting = !this.state.forgetEmail
        if (hide) {
            forgetting = false
        }
        this.setState({
            forgetEmail: forgetting,
        })
    }

    doRest(values) {
        const payload = {
            username: values.person.username,
            password: values.person.password,
            pdx_name: values.pdx,
        }

        sendRestLogin({
            path: 'account/login',
            payload,
            method: 'POST',
            noErrorHandling: true,
        })
            .then(res => {
                let error = false
                if (res.error && res.error.code >= 400) {
                    // try second time, we could have login with another apikey/session. Now  it is cleared.

                    sendRest({
                        path: 'account/login',
                        payload,
                        method: 'POST',
                        noErrorHandling: true,
                    })
                        .then(res => {
                            let error = false
                            if (res.error && res.error.code >= 400) {
                                error = true
                            } else {
                                error = false
                                this.props.logonReady()
                            }
                            this.setState({
                                isError: error,
                                isSubmitting: false,
                            })
                        })
                        .catch(() => {
                            this.setState({
                                isError: true,
                                isSubmitting: false,
                            })
                        })
                } else {
                    error = false
                    this.props.logonReady()
                }
            })
            .catch(() => {
                sendRest({
                    path: 'account/login',
                    payload,
                    method: 'POST',
                    noErrorHandling: true,
                })
                    .then(res => {
                        let error = false
                        if (res.error && res.error.code >= 400) {
                            error = true
                        } else {
                            error = false
                            this.props.logonReady()
                        }
                        this.setState({
                            isError: error,
                            isSubmitting: false,
                        })
                    })
                    .catch(() => {
                        this.setState({
                            isError: true,
                            isSubmitting: false,
                        })
                    })
            })
    }

    render() {
        const { locale } = this.props
        const { isError, isSubmitting, forgetEmail, pdx, isLogout } = this.state
        if (isLogout) return null
        return (
            <LoginWrap>
                <Heading>{t(locale, 'login__label--title')}</Heading>
                <FlexFormik
                    initialValues={{ person: { username: '', password: '' }, pdx: pdx }}
                    validationSchema={null}
                    enableReinitialize={true}
                >
                    {({ values, errors }) => {
                        const hasErrors = Object.keys(errors).length > 0

                        return (
                            <FlexForm
                                onSubmit={e => {
                                    e.preventDefault()
                                    e.stopPropagation()
                                    this.setSubmitting(true)
                                    toast.dismiss()
                                    checkCredentials()
                                        .then(res => {
                                            if (String(res) === 'Authorized') {
                                                sendRest({
                                                    path: 'account/logout',
                                                    method: 'POST',
                                                    noErrorHandling: true,
                                                })
                                                    .then(() => {
                                                        this.doRest(values)
                                                    })
                                                    .catch(err => {
                                                        console.log('undefined logout error')
                                                        toast.dismiss()
                                                        toastConnectionError(err)
                                                        return
                                                    })
                                            }
                                        })
                                        .catch(() => {
                                            if (
                                                values.person.username.length === 0 ||
                                                values.person.password.length === 0
                                            ) {
                                                this.setError(true)
                                                toastInfo(t(locale, 'login__label--missing-fields'))
                                                this.setSubmitting(false)
                                            } else if (values.person.password.length < 12) {
                                                this.setError(true)
                                                toastInfo(t(locale, 'login__label--password-length'))
                                                this.setSubmitting(false)
                                            } else {
                                                this.setState({
                                                    forgetEmail: false,
                                                    isError: false,
                                                    isSubmitting: true,
                                                })
                                                this.doRest(values)
                                            }
                                        })
                                }}
                            >
                                <Inputs>
                                    <InputColumn>
                                        <Label>{t(locale, 'person__label--username')}</Label>
                                        <EditableField
                                            id="person.username"
                                            required
                                            onChange={() => {
                                                this.setError(false)
                                                toast.dismiss()
                                            }}
                                            onInput={v => {
                                                console.log(v, 'foo')
                                            }}
                                        />
                                    </InputColumn>
                                    <InputColumn>
                                        <Label>{t(locale, 'person__label--password')}</Label>
                                        <EditableField
                                            id="person.password"
                                            type="password"
                                            required
                                            onChange={() => {
                                                this.setError(false)
                                                toast.dismiss()
                                            }}
                                        />
                                    </InputColumn>
                                </Inputs>
                                <ActionWrapper>
                                    <Submit disabled={isSubmitting || hasErrors}>
                                        {t(
                                            locale,
                                            isSubmitting ? 'ui__general--processing' : 'login__label--login-button'
                                        )}
                                    </Submit>
                                </ActionWrapper>
                                {isError && <Label>{t(locale, 'login__label--login-error')}</Label>}
                            </FlexForm>
                        )
                    }}
                </FlexFormik>
                {forgetEmail && <ForgotPassword pdx={pdx} setReady={this.setEmailReady} />}
                {!forgetEmail && (
                    <ForgetButton
                        onClick={() => {
                            this.setState({ forgetEmail: !this.state.forgetEmail })
                        }}
                    >
                        {t(locale, 'login__label--did-you-forgot-password')}
                    </ForgetButton>
                )}
                <ToastContainer
                    position={toast.POSITION.TOP_CENTER}
                    autoClose={false}
                    transition={Slide}
                    hideProgressBar={true}
                    closeButton={false}
                />
            </LoginWrap>
        )
    }
}

const LoginWrap = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
`

const FlexFormik = styled(Formik)`
    display: flex;
    align-items: center;
    justify-content: end;
`

const FlexForm = styled.form`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: end;
    width: 46em;
`

const Heading = styled.div`
    width: 282px;
    height: 37px;
    font-family: 'Roboto Slab', serif;
    font-size: 28px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #202020;
    margin-top: 24px;
`

const Inputs = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 3em;
`

const InputColumn = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    align-content: start;
    margin-right: 0.5em;
    width: 100%;
`

const ActionWrapper = styled.div`
    padding-top: 2em;
    display: flex;
    align-items: center;
`

const Submit = styled(Button).attrs(() => ({ type: 'submit' }))`
    display: flex;
    align-content: start;
`

const Label = styled.label`
    justify-content: flex-start;
`

const ForgetButton = styled.button`
    margin-top: 1em;
    border: none;
    background: none;
    :hover {
        text-decoration: underline;
    }
    color: #4498d3;
    width: 173px;
    height: 22px;
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    font-weight: 600;
    white-space: nowrap;
`
Login.propTypes = {
    locale: PropTypes.string.isRequired,
    isLogout: PropTypes.bool.isRequired,
}

export default withLocale(Login)
